// 国旗
import country3 from "@/static/images/country/3.svg";
import country9 from "@/static/images/country/9.svg";
// import country10 from '@/static/images/country/10.svg';
import country2 from "@/static/images/country/2.svg";
import country11 from "@/static/images/country/11.svg";
import countryDubai from "@/static/images/country/Dubai.png";
import country1 from "@/static/images/country/1.svg";
import country4 from "@/static/images/country/4.svg";
import country8 from "@/static/images/country/8.svg";
import country6 from "@/static/images/country/6.svg";
import country7 from "@/static/images/country/7.svg";
import country13 from "@/static/images/country/13.svg";
import country16 from "@/static/images/country/16.svg";
import country17 from "@/static/images/country/17.svg";
import country18 from "@/static/images/country/18.svg";
import country19 from "@/static/images/country/19.svg";
import countryTokyo from "@/static/images/country/Tokyo.svg";
import countryCairo from "@/static/images/country/Cairo.png";
import countryBahrain from "@/static/images/country/Bahrain.png";
import countrySofia from "@/static/images/country/Sofia.png";
import countryAthens from "@/static/images/country/Athens.png";
import countryLumpur from "@/static/images/country/Lumpur.png";
import countryLondon from "@/static/images/country/London.png";
import countryMuscat from "@/static/images/country/Muscat.png";
import countryKuwait from "@/static/images/country/Kuwait.png";
import countryMarseille from "@/static/images/country/Marseille.png";
import countryKarachi from "@/static/images/country/Karachi.png";
import countryNepal from "@/static/images/country/Nepal.png";
import countryRussia from "@/static/images/country/Russia.png";
import countryArgentina from "@/static/images/country/Argentina.png";
import countryJakarta from "@/static/images/country/Jakarta.png";
import countryMexico from "@/static/images/country/Mexico.png";
import countryChile from "@/static/images/country/Chile.webp";
import countryMyanmar from "@/static/images/country/Myanmar.png";
import conuntryIraq from "@/static/images/country/Iraq.png";
import conuntryColombia from "@/static/images/country/Colombia.png";

// banner
import Bnagkok from "@/static/images/regionBanner/bangkok.jpg";
import Dhaka from "@/static/images/regionBanner/Dhaka.jpg";
// import DUbai from '@/static/images/regionBanner/DUbai.jpg';
// import frankfurt from '@/static/images/regionBanner/frankfurt.jpg';
import hanoi from "@/static/images/regionBanner/hanoi.jpg";
import hochiminhcity from "@/static/images/regionBanner/hochiminhcity.jpg";
import hongkong from "@/static/images/regionBanner/hongkong.jpg";
import istanbul from "@/static/images/regionBanner/istanbul.jpg";
import frankfurt from "@/static/images/regionBanner/frankfurt.jpg";
import Johannesburg from "@/static/images/regionBanner/Johannesburg.jpg";
import PhnomPenh from "@/static/images/regionBanner/PhnomPenh.jpg";
import riyadh from "@/static/images/regionBanner/riyadh.jpg";
import DUbai from "@/static/images/regionBanner/DUbai.jpg";
import seoul from "@/static/images/regionBanner/seoul.jpg";
import SiliconValley from "@/static/images/regionBanner/SiliconValley.jpg";
import taipeichina from "@/static/images/regionBanner/taipeichina.jpg";
import washington from "@/static/images/regionBanner/washington.jpg";
import Manila from "@/static/images/regionBanner/Manila.jpg";
import Singapore from "@/static/images/regionBanner/Singapore.jpg";
import Saopaulo from "@/static/images/regionBanner/saopaulo.jpg";
import Jeddah from "@/static/images/regionBanner/jeddah.jpg";
import Tokyo from "@/static/images/regionBanner/Tokyo.jpg";
import Cairo from "@/static/images/regionBanner/Cairo.jpg";
import Bahrain from "@/static/images/regionBanner/Bahrain.jpg";
import Sofia from "@/static/images/regionBanner/Sofia.jpg";
import Athens from "@/static/images/regionBanner/Athens.jpg";
import Lumpur from "@/static/images/regionBanner/Lumpur.jpg";
import London from "@/static/images/regionBanner/london.jpg";
import Muscat from "@/static/images/regionBanner/Muscat.jpg";
import Kuwait from "@/static/images/regionBanner/Kuwait.jpg";
import Marseille from "@/static/images/regionBanner/Marseille.jpg";
import Karachi from "@/static/images/regionBanner/Karachi.jpg";
import Nepal from "@/static/images/regionBanner/Nepal.jpg";
import Moscow from "@/static/images/regionBanner/Moscow.jpg";
import BuenosAires from "@/static/images/regionBanner/BuenosAires.jpg";
import Indonesia from "@/static/images/regionBanner/Indonesia.jpg";
import MexicoCity from "@/static/images/regionBanner/MexicoCity.jpg";
import Santiago from "@/static/images/regionBanner/Santiago.jpg";
import Yangon from "@/static/images/regionBanner/Yangon.jpg";
import Baghdad from "@/static/images/regionBanner/Baghdad.jpg";
import Bogota from "@/static/images/regionBanner/Bogota.jpg";
import Doha from "@/static/images/regionBanner/Doha.jpg";
export interface nodeType {
  text: string; //节点名称（英文）
  continent?: string; //所属洲
  url?: string; //跳转https://go.lightnode.com/url
  value?: string; //节点唯一值，按顺序增加即可
  tab: string; //所属平台（LightNode | VU&DO | LightNode VU&DO）
  banner?: any; //测速页面的banner图片
  // https://github.com/lipis/flag-icons
  countryImgCode?: string; //在https://flagicons.lipis.dev/中搜索国家获取国旗标识码
  countryImg?: string; //国家国旗(现已统一使用countryImgCode)
  position: {
    //所在地图位置
    top: number;
    left: number;
  };
  city: any; //多语言城市名
  country: any; //多语言国家名
  introc?: any; //多语言介绍
  regionCode?: string; //测速code
  oldRegionCode?: string; //旧的测速code
  lineType?: any; //线路
  tagLink?: any; //标签跳转
}
export const nodeMapData: nodeType[] = [
  {
    text: "Silicon Valley",
    continent: "North America",
    url: "the-united-states-vps",
    value: "1",
    tab: "LightNode",
    countryImg: country3,
    countryImgCode: "us",
    banner: SiliconValley,
    position: {
      top: 208,
      left: 134,
    },
    regionCode: "us-siliconvalley-1",
    city: {
      "en-US": "Silicon Valley",
      "zh-CN": "硅谷",
      "zh-HK": "矽谷",
      "vi-VN": "Thung lũng Silicon",
      "fr-FR": "La Silicon Valley",
    },
    country: {
      "en-US": "USA",
      "zh-CN": "美国",
      "zh-HK": "美國",
      "vi-VN": "Hoa Kỳ",
      "fr-FR": "États-Unis",
    },
    introc: {
      "en-US": [
        "is a region in Northern California that serves as a global center for high technology and innovation.",
        "Silicon Valley is a city in the western United States, located in North America.",
        "{platformName}'s Silicon Valley node in the United States is a  BGP line, and similar node is: Washington.",
      ],
      "zh-CN": [
        "是北加州的一个地区，是高科技和创新的全球中心。",
        "是美国西部的一个城市，位于北美洲。",
        "{platformName}美国硅谷节点为BGP线路，类似的节点有：华盛顿。",
      ],
      "zh-HK": [
        "是北加州的一個地區，是高科技和創新的全球中心。",
        "矽谷是美國西部的一個城市，位於北美洲。",
        "{platformName}美國矽谷節點為BGP線路，類似的節點有：華盛頓。",
      ],
      "vi-VN": [
        "Là một khu vực của Bắc California, là trung tâm toàn cầu về công nghệ cao và sáng tạo đổi mới.",
        "Là một thành phố ở miền Tây nước Mỹ, nằm ở Bắc Mỹ.",
        "Nút Thung lũng Silicon của {platformName} ở Mỹ là đường dây BGP, nút tương tự gồm: Washington.",
      ],
      "fr-FR": [
        "est une région du nord de la Californie qui sert de centre mondial pour la haute technologie et l'innovation'",
        "La Vallée de la Silicone est une ville de l'ouest des États-Unis, située en Amérique du Nord",
        "Le nœud de la Vallée de la Silicone de {platformName} aux États-Unis est une ligne BGP, et un nœud similaire est : Washington.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "The USA VPS",
        link: "https://go.lightnode.com/the-united-states-vps",
      },
      "zh-CN": {
        title: "美国VPS",
        link: "https://go.lightnode.com/zh/the-united-states-vps",
      },
      "zh-HK": {
        title: "美國VPS",
        link: "https://go.lightnode.com/zh/the-united-states-vps",
      },
      "vi-VN": {
        title: "VPS Hoa Kỳ",
        link: "https://go.lightnode.com/the-united-states-vps",
      },
      "fr-FR": {
        title: "VPS américain",
        link: "https://go.lightnode.com/the-united-states-vps",
      },
    },
  },
  {
    text: "Washington",
    continent: "North America",
    url: "washington-vps",
    value: "2",
    tab: "LightNode",
    // countryImg: country3,
    countryImgCode: "us",
    banner: washington,
    position: {
      top: 194,
      left: 256,
    },
    regionCode: "us-washington-1",
    city: {
      "en-US": "Washington",
      "zh-CN": "华盛顿",
      "zh-HK": "華盛頓",
      "vi-VN": "Washington",
      "fr-FR": "Washington",
    },
    // country: 'D.C., USA',
    country: {
      "en-US": "USA",
      "zh-CN": "美国",
      "zh-HK": "美國",
      "vi-VN": "Hoa Kỳ",
      "fr-FR": "États-Unis",
    },
    introc: {
      "en-US": [
        ", D.C., formally the District of Columbia, also known as just Washington or just D.C., is the capital city and only federal district of the United States.",
        "Washington is the capital of the United States and is located in North America.",
        "{platformName}'s Washington node is a  BGP line, and similar node is: Silicon Valley.",
      ],
      "zh-CN": [
        ", 特区，正式名称为哥伦比亚特区，也被称为华盛顿或简称为D.C，是美国的首府和唯一的联邦区。",
        "华盛顿是美国首都，位于北美洲 。",
        "{platformName}美国华盛顿节点为BGP线路，类似的节点有：硅谷。",
      ],
      "zh-HK": [
        ", 特區，正式名稱為哥倫比亞特區，也被稱為華盛頓或簡稱為D.C，是美國的首府和唯一的聯邦區。",
        "華盛頓是美國首都，位於北美洲。",
        "{platformName}美國華盛頓節點為BGP線路，類似的節點有：矽谷。",
      ],
      "vi-VN": [
        ", Đặc khu, tên gọi chính thức là đặc khu Columbia, còn được gọi là Washington hoặc gọi tắt là D.C, là thủ phủ và quận liên bang duy nhất của nước Mỹ.",
        "Washington là thủ đô của nước Mỹ, nằm ở Bắc Mỹ.",
        "Nút Washington của {platformName} ở Mỹ là đường dây BGP, nút tương tự gồm: Thung lũng Silicon.",
      ],
      "fr-FR": [
        ", D.C., formellement le District of Columbia, également connu sous le nom de Washington ou D.C., est la capitale et le seul district fédéral des États-Unis",
        "Washington est la capitale des États-Unis et est située en Amérique du Nord.",
        "Le nœud Washington de {platformName} est une ligne BGP, et un nœud similaire est : Vallée de la Silicone.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "The USA VPS",
        link: "https://go.lightnode.com/the-united-states-vps",
      },
      "zh-CN": {
        title: "美国VPS",
        link: "https://go.lightnode.com/zh/the-united-states-vps",
      },
      "zh-HK": {
        title: "美國VPS",
        link: "https://go.lightnode.com/zh/the-united-states-vps",
      },
      "vi-VN": {
        title: "VPS Hoa Kỳ",
        link: "https://go.lightnode.com/the-united-states-vps",
      },
      "fr-FR": {
        title: "VPS américain",
        link: "https://go.lightnode.com/the-united-states-vps",
      },
    },
  },
  {
    text: "Frankfurt",
    continent: "Europe",
    url: "germany-vps",
    value: "3",
    tab: "LightNode",
    countryImg: country9,
    countryImgCode: "de",
    banner: frankfurt,
    position: {
      top: 162,
      left: 512,
    },
    regionCode: "ger-frankfurt-1",
    city: {
      "en-US": "Frankfurt",
      "zh-CN": "法兰克福",
      "zh-HK": "法蘭克福",
      "vi-VN": "Frankfurt",
      "fr-FR": "Francfort",
    },
    // country: 'Germany',
    country: {
      "en-US": "Germany",
      "zh-CN": "德国",
      "zh-HK": "德國",
      "vi-VN": "Đức",
      "fr-FR": "Allemagne",
    },
    introc: {
      "en-US": [
        ", officially Frankfurt am Main,is the most populous city in the German state of Hesse.",
        "Frankfurt is centrally located in Germany and Europe.",
        "{platformName} Frankfurt node is a  BGP line, and similar node is:  Istanbul.",
      ],
      "zh-CN": [
        "，正式名称为美因河畔法兰克福，是德国黑森州人口最多的城市。",
        "法兰克福位于德国和欧洲的中心位置。",
        "{platformName}法兰克福节点为BGP线路，类似的节点有：伊斯坦布尔。",
      ],
      "zh-HK": [
        "，正式名稱為美因河畔法蘭克福，是德國黑森州人口最多的城市。",
        "法蘭克福位於德國和歐洲的中心位置。",
        "{platformName}法蘭克福節點為BGP線路，類似的節點有：伊斯坦布爾。",
      ],
      "vi-VN": [
        ", tên gọi chính thức là Frankfurt trên sông Main, là thành phố đông dân nhất ở bang Hessen nước Đức.",
        "Frankfurt nằm ở vị trí trung tâm của nước Đức và Châu Âu.",
        "Nút Frankfurt của {platformName} là đường dây BGP, nút tương tự gồm: Istanbul.",
      ],
      "fr-FR": [
        ", officiellement, Francfort-sur-le-Main est la plus grande ville de l'État allemand de Hesse.",
        "Francfort est située au centre de l'Allemagne et de l'Europe.",
        "Le noeud {platformName} Frankfurt est une ligne BGP, et un noeud similaire est : Istanbul.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Germany VPS",
        link: "https://go.lightnode.com/germany-vps",
      },
      "zh-CN": {
        title: "德国VPS",
        link: "https://go.lightnode.com/zh/germany-vps",
      },
      "zh-HK": {
        title: "德國VPS",
        link: "https://go.lightnode.com/zh/germany-vps",
      },
      "vi-VN": {
        title: "VPS Đức",
        link: "https://go.lightnode.com/germany-vps",
      },
      "fr-FR": {
        title: "VPS Allemagne",
        link: "https://go.lightnode.com/germany-vps",
      },
    },
  },
  {
    text: "Istanbul",
    continent: "Asia",
    url: "turkey-vps",
    value: "4",
    tab: "LightNode",
    countryImg: country2,
    countryImgCode: "tr",
    banner: istanbul,
    position: {
      top: 200,
      left: 582,
    },
    regionCode: "tur-istanbul-3",
    city: {
      "en-US": "Istanbul",
      "zh-CN": "伊斯坦布尔",
      "zh-HK": "伊斯坦布爾",
      "vi-VN": "Istanbul",
      "fr-FR": "Istanbul",
    },
    // country: 'Turkey',
    country: {
      "en-US": "Turkey",
      "zh-CN": "土耳其",
      "zh-HK": "土耳其",
      "vi-VN": "Thổ Nhĩ Kỳ",
      "fr-FR": "Turquie",
    },
    introc: {
      "en-US": [
        ", formerly known as Constantinople, is the largest city in Turkey, serving as the country's economic, cultural and historic hub. ",
        "Istanbul straddles the Bosphorus, located in Europe and Asia.",
        "The {platformName} Istanbul node is a  BGP line, and similar node is:  Frankfurt.",
      ],
      "zh-CN": [
        "，前身为君士坦丁堡，是土耳其最大的城市，是该国的经济，文化和历史中心。",
        "伊斯坦布尔横跨博斯普鲁斯海峡，位于欧洲和亚洲。",
        "{platformName}伊斯坦布尔节点为BGP线路，类似的节点有：法兰克福。",
      ],
      "zh-HK": [
        "，前身為君士坦丁堡，是土耳其最大的城市，是該國的經濟，文化和歷史中心。",
        "伊斯坦布橫跨博斯普魯斯海峽，位於歐洲和亞洲。",
        "{platformName}伊斯坦布爾節點為BGP線路，類似的節點有：法蘭克福。",
      ],
      "vi-VN": [
        ", trước đây là Constantinople, là thành phố lớn nhất của Thổ Nhĩ Kỳ, là trung tâm kinh tế, văn hóa và lịch sử của đất nước này.",
        "Istanbul bắc ngang qua eo biển Bosphorus, nằm ở Châu Âu và Châu Á.",
        "Nút Istanbul của {platformName} là đường dây BGP, nút tương tự gồm: Frankfurt.",
      ],
      "fr-FR": [
        ", formellement appelée Constantinople, est la plus grande ville de Turquie, servant de centre économique, culturel et historique du pays.",
        "Istanbul est située entre l'Europe et l'Asie, à cheval sur le Bosphore.",
        "Le nœud {platformName} Istanbul est une ligne BGP, et un nœud similaire est: Francfort.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Turkey VPS",
        link: "https://go.lightnode.com/turkey-vps",
      },
      "zh-CN": {
        title: "土耳其VPS",
        link: "https://go.lightnode.com/zh/turkey-vps",
      },
      "zh-HK": {
        title: "土耳其VPS",
        link: "https://go.lightnode.com/zh/turkey-vps",
      },
      "vi-VN": {
        title: "VPS Thổ Nhĩ Kỳ",
        link: "https://go.lightnode.com/turkey-vps",
      },
      "fr-FR": {
        title: "VPS Turquie",
        link: "https://go.lightnode.com/turkey-vps",
      },
    },
  },
  {
    text: "Riyadh",
    continent: "Asia",
    url: "saudi-arabia-vps",
    value: "5",
    tab: "LightNode",
    countryImg: country11,
    countryImgCode: "sa",
    banner: riyadh,
    position: {
      top: 265,
      left: 618,
    },
    regionCode: "sau-riyadh-1",
    city: {
      "en-US": "Riyadh",
      "zh-CN": "利雅得",
      "zh-HK": "利雅得",
      "vi-VN": "Riyadh",
      "fr-FR": "Riyad",
    },
    // country: 'Saudi Arabia',
    country: {
      "en-US": "Saudi Arabia",
      "zh-CN": "沙特阿拉伯",
      "zh-HK": "沙烏地阿拉伯",
      "vi-VN": "Ả Rập Saudi",
      "fr-FR": "Arabie saoudite",
    },
    introc: {
      "en-US": [
        "is the capital of Saudi Arabia and one of the largest cities on the Arabian Peninsula. ",
        "Jeddah, a port city in The Mecca Province of Saudi Arabia, is located on the east coast of the Red Sea.",
        "{platformName} Jeddah node is a  BGP line, similar nodes are: Riyadh, Dubai.",
      ],
      "zh-CN": [
        "是沙特阿拉伯的首都，也是阿拉伯半岛上最大的城市之一。",
        "利雅得是沙特阿拉伯的首都及最大城市，也是利雅得省的省会，位于亚洲西南部。",
        "{platformName}利雅得节点为BGP线路，类似的节点有：吉达、迪拜。",
      ],
      "zh-HK": [
        "是沙烏地阿拉伯的首都，也是阿拉伯半島上最大的城市之一。",
        "利雅得是沙烏地阿拉伯的首都及最大城市，也是利雅德省的省會，位於亞洲西南部。",
        "{platformName}利雅得節點為BGP線路，類似的節點有：吉達、迪拜。",
      ],
      "vi-VN": [
        "là thủ đô của Ả Rập Xê Út, cũng là một trong những thành phố lớn nhất trên Bán đảo Ả Rập.",
        "Riyadh là thủ đô và là thành phố lớn nhất của Ả Rập Xê Út, cũng là thủ phủ của tỉnh Riyadh, nằm ở Tây Nam Á.",
        "Nút Riyadh của {platformName} là đường dây BGP, các nút tương tự gồm: Jeddah, Dubai.",
      ],
      "fr-FR": [
        "est la capitale de l'Arabie saoudite et l'une des plus grandes villes de la Péninsule arabique.",
        "Jeddah, une ville portuaire de la province de La Mecque en Arabie Saoudite, est située sur la côte est de la Mer Rouge",
        "{platformName} Jeddah est une ligne BGP, les noeuds similaires sont : Riyadh, Dubai.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Saudi Arabia VPS",
        link: "https://go.lightnode.com/saudi-arabia-vps",
      },
      "zh-CN": {
        title: "沙特阿拉伯VPS",
        link: "https://go.lightnode.com/zh/saudi-arabia-vps",
      },
      "zh-HK": {
        title: "沙烏地阿拉伯VPS",
        link: "https://go.lightnode.com/zh/saudi-arabia-vps",
      },
      "vi-VN": {
        title: "VPS Ả Rập Saudi",
        link: "https://go.lightnode.com/saudi-arabia-vps",
      },
      "fr-FR": {
        title: "VPS Arabie Saoudite",
        link: "https://go.lightnode.com/saudi-arabia-vps",
      },
    },
  },
  {
    text: "Dubai",
    continent: "Asia",
    url: "the-united-arab-emirates-vps",
    value: "6",
    tab: "LightNode",
    countryImg: countryDubai,
    countryImgCode: "ae",
    banner: DUbai,
    position: {
      top: 248,
      left: 634,
    },
    regionCode: "uae-dubai-1",
    city: {
      "en-US": "Dubai",
      "zh-CN": "迪拜",
      "zh-HK": "迪拜",
      "vi-VN": "Dubai",
      "fr-FR": "Dubaï",
    },
    // country: 'United Arab Emirates',
    country: {
      "en-US": "UAE",
      "zh-CN": "阿联酋",
      "zh-HK": "阿聯酋",
      "vi-VN": "UAE",
      "fr-FR": "EUA",
    },
    introc: {
      "en-US": [
        "is the most populous city in the United Arab Emirates (UAE) and the capital of the Emirate of Dubai.",
        "Dubai is the capital of the Emirate of Dubai, located in the eastern part of the Arabian Peninsula on the Coast of the Persian Gulf.",
        "{platformName} Dubai node is a BGP line, similar nodes are: Riyadh, Dubai.",
      ],
      "zh-CN": [
        "是阿拉伯联合酋长国（阿联酋）人口最多的城市，也是迪拜酋长国的首都。",
        "迪拜是迪拜酋长国的首都，位于波斯湾沿岸阿拉伯半岛的东部。",
        "{platformName}迪拜节点为BGP线路，类似的节点有：利雅得、迪拜。",
      ],
      "zh-HK": [
        "是阿拉伯聯合酋長國（阿聯酋）人口最多的城市，也是迪拜酋長國的首都。",
        "迪拜是迪拜酋長國的首都，位於波斯灣沿岸阿拉伯半島的東部。",
        "{platformName}迪拜節點為BGP線路，類似的節點有：利雅德、迪拜。",
      ],
      "vi-VN": [
        "là thành phố đông dân nhất của Các Tiểu vương quốc Ả Rập Thống nhất (UAE), cũng là thủ đô của Tiểu vương quốc Dubai.",
        "Dubai là thủ đô của Tiểu vương quốc Dubai, nằm ở phía Đông của bán đảo Ả Rập dọc theo Vịnh Ba Tư.",
        "Nút Dubai của {platformName} là đường dây BGP, các nút tương tự gồm: Riyadh, Dubai.",
      ],
      "fr-FR": [
        "est la ville la plus peuplée des Émirats arabes unis (EAU) et la capitale de l'émirat de Dubai.",
        "Dubai est la capitale de l'émirat de Dubaï, qui se trouve dans la partie orientale de la Péninsule arabique, sur la Côte du Golfe Persique.",
        "Le noeud {platformName} Dubai est une ligne BGP, les noeuds similaires sont : Riyadh, Dubai.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "UAE VPS",
        link: "https://go.lightnode.com/the-united-arab-emirates-vps",
      },
      "zh-CN": {
        title: "阿联酋VPS",
        link: "https://go.lightnode.com/zh/the-united-arab-emirates-vps",
      },
      "zh-HK": {
        title: "阿聯酋VPS",
        link: "https://go.lightnode.com/zh/the-united-arab-emirates-vps",
      },
      "vi-VN": {
        title: "VPS của UAE",
        link: "https://go.lightnode.com/the-united-arab-emirates-vps",
      },
      "fr-FR": {
        title: "VPS EAU",
        link: "https://go.lightnode.com/the-united-arab-emirates-vps",
      },
    },
  },
  {
    text: "Bangkok",
    continent: "Asia",
    url: "thailand-vps",
    value: "7",
    tab: "LightNode",
    countryImg: country1,
    countryImgCode: "th",
    banner: Bnagkok,
    position: {
      top: 275,
      left: 775,
    },
    regionCode: "tha-bangkok-1",
    city: {
      "en-US": "Bangkok",
      "zh-CN": "曼谷",
      "zh-HK": "曼谷",
      "vi-VN": "Bangkok",
      "fr-FR": "Bangkok",
    },
    // country: 'Thailand',
    country: {
      "en-US": "Thailand",
      "zh-CN": "泰国",
      "zh-HK": "泰國",
      "vi-VN": "Thái Lan",
      "fr-FR": "Thaïlande",
    },
    introc: {
      "en-US": [
        "is the capital and most populous city of Thailand,and is the economic centre of Thailand, and the heart of the country's investment and development.",
        "Bangkok is the capital and largest city of Thailand. Located on the east bank of the Chao Phraya River, near the Gulf of Thailand.",
        "{platformName} Bangkok node is a BGP line, similar nodes are: Phnom Penh, Cambodia.",
      ],
      "zh-CN": [
        "是泰国的首都和人口最多的城市,是泰国的经济中心，也是泰国投资和发展的中心。",
        "曼谷，是泰国首都与最大城市。位于昭披耶河东岸，近泰国湾。",
        "{platformName}曼谷节点为BGP线路，类似的节点有：金边。",
      ],
      "zh-HK": [
        "是泰國的首都和人口最多的城市,是泰國的經濟中心，也是泰國投資和發展的中心。",
        "曼谷，是泰國首都與最大城市。 位於昭披耶河東岸，近泰國灣。",
        "{platformName}曼谷節點為BGP線路，類似的節點有：金邊。",
      ],
      "vi-VN": [
        "là thủ đô và là thành phố đông dân nhất của Thái Lan, trung tâm kinh tế của Thái Lan, cũng là trung tâm đầu tư và phát triển của Thái Lan.",
        "Bangkok, là thủ đô và là thành phố lớn nhất của Thái Lan. Nằm trên bờ Đông của sông Chao Phraya, gần Vịnh Thái Lan.",
        "Nút Bangkok của {platformName} là đường dây BGP, nút tương tự gồm: Phnom Pênh.",
      ],
      "fr-FR": [
        "est la capitale et la ville la plus peuplée de Thaïlande. Elle est le centre économique de la Thaïlande, et le centre des investissements et du développement du pays.",
        "Bangkok est la capitale et la plus grande ville de Thaïlande. Située sur la rive est de la rivière Chao Phraya, près du Golfe de Thaïlande.",
        "Le nœud {platformName} Bangkok est une ligne BGP, les nœuds similaires sont : Phnom Penh, Cambodge.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Thailand VPS",
        link: "https://go.lightnode.com/thailand-vps",
      },
      "zh-CN": {
        title: "泰国VPS",
        link: "https://go.lightnode.com/zh/thailand-vps",
      },
      "zh-HK": {
        title: "泰國VPS",
        link: "https://go.lightnode.com/zh/thailand-vps",
      },
      "vi-VN": {
        title: "VPS Thái Lan",
        link: "https://go.lightnode.com/thailand-vps",
      },
      "fr-FR": {
        title: "VPS Thaïlande",
        link: "https://go.lightnode.com/thailand-vps",
      },
    },
  },
  {
    text: "Hanoi",
    continent: "Asia",
    url: "hanoi-vps",
    value: "8",
    tab: "LightNode",
    countryImg: country8,
    countryImgCode: "vn",
    banner: hanoi,
    position: {
      top: 258,
      left: 786,
    },
    regionCode: "vn-henei-1",
    city: {
      "en-US": "Hanoi",
      "zh-CN": "河内",
      "zh-HK": "河內",
      "vi-VN": "Hà Nội",
      "fr-FR": "Hanoi",
    },
    // country: 'Vietnam',
    country: {
      "en-US": "Vietnam",
      "zh-CN": "越南",
      "zh-HK": "越南",
      "vi-VN": "Việt Nam",
      "fr-FR": "Vietnam",
    },
    introc: {
      "en-US": [
        "is the capital city of Vietnam,The second largest city in Vietnam.Located within the Red River Delta, Hanoi is the cultural and political centre of Vietnam.",
        "Hanoi is the capital of Vietnam and is located in southeastern Asia.",
        "The {platformName} Hanoi node is a BGP line, and similar node is: Ho Chi Minh.",
      ],
      "zh-CN": [
        "是越南的首都,越南第二大城市。河内位于红河三角洲，是越南的文化和政治中心。",
        "河内是越南的首都，位于亚洲东南部。",
        "{platformName}河内节点为BGP线路，类似的节点有：胡志明市。",
      ],
      "zh-HK": [
        "是越南的首都，越南第二大城市。河內位於紅河三角洲，是越南的文化和政治中心。",
        "河內是越南的首都，位於亞洲東南部。",
        "{platformName}河內節點為BGP線路，類似的節點有：胡志明市。",
      ],
      "vi-VN": [
        "là thủ đô của Việt Nam, thành phố lớn thứ hai của Việt Nam. Hà Nội nằm ở đồng bằng sông Hồng, là trung tâm văn hóa và chính trị của Việt Nam.",
        "Hà Nội là thủ đô của Việt Nam, nằm ở phía Đông Nam của Châu Á.",
        "Nút Hà Nội của {platformName} là đường dây BGP, nút tương tự gồm: Thành phố Hồ Chí Minh.",
      ],
      "fr-FR": [
        "est la capitale du Vietnam, la deuxième plus grande ville du Vietnam, est située dans le Delta du Rivière Rouge, Hanoi est le centre culturel et politique du Vietnam.",
        "Hanoi est la capitale du Vietnam et se situe dans le sud-est de l'Asie.",
        "Le noeud Hanoi de {platformName} est une ligne BGP, et le noeud similaire est : Ho Chi Minh.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Vietnam VPS",
        link: "https://go.lightnode.com/vietnam-vps",
      },
      "zh-CN": {
        title: "越南VPS",
        link: "https://go.lightnode.com/zh/vietnam-vps",
      },
      "zh-HK": {
        title: "越南VPS",
        link: "https://go.lightnode.com/zh/vietnam-vps",
      },
      "vi-VN": {
        title: "VPS Việt Nam",
        link: "https://go.lightnode.com/vietnam-vps",
      },
      "fr-FR": {
        title: "VPS Vietnam",
        link: "https://go.lightnode.com/vietnam-vps",
      },
    },
  },
  {
    text: "Phnom Penh",
    continent: "Asia",
    url: "cambodia-vps",
    value: "9",
    tab: "LightNode",
    countryImg: country4,
    countryImgCode: "kh",
    banner: PhnomPenh,
    position: {
      top: 285,
      left: 786,
    },
    regionCode: "khn-phnompenh-1",
    city: {
      "en-US": "Phnom Penh",
      "zh-CN": "金边",
      "zh-HK": "金边",
      "vi-VN": "Phnom Penh",
      "fr-FR": "Phnom Penh",
    },
    // country: 'Cambodia',
    country: {
      "en-US": "Cambodia",
      "zh-CN": "柬埔寨",
      "zh-HK": "柬埔寨",
      "vi-VN": "Campuchia",
      "fr-FR": "Cambodge",
    },
    introc: {
      "en-US": [
        "is the capital and most populous city of Cambodia,Once known as the “Pearl of Asia”.",
        "Phnom Penh is the capital and largest city of Cambodia.",
        "{platformName} Phnom Penh node is a BGP line, , and similar node is: Ho Chi Minh, Vietnam.",
      ],
      "zh-CN": [
        "是柬埔寨的首都和人口最多的城市,曾被称为“亚洲明珠”。",
        "金边是柬埔寨的首都和最大都市。",
        "{platformName}金边节点为BGP线路，类似的节点有：胡志明市。",
      ],
      "zh-HK": [
        "是柬埔寨的首都和人口最多的城市，曾被稱為“亞洲明珠”。",
        "金邊是柬埔寨的首都和最大都市。",
        "{platformName}金邊節點為BGP線路，類似的節點有：胡志明市。",
      ],
      "vi-VN": [
        'là thủ đô và là thành phố đông dân nhất của Campuchia, từng được mệnh danh là "Hòn ngọc châu Á".',
        "Phnom Pênh là thủ đô và là thành phố lớn nhất của Campuchia.",
        "Nút Phnom Pênh của {platformName} là đường dây BGP, nút tương tự gồm: Thành phố Hồ Chí Minh.",
      ],
      "fr-FR": [
        "est la capitale et la ville la plus peuplée du Cambodge, appelée autrefois la 'Perle de l'Asie.",
        "Phnom Penh est la capitale et la plus grande ville du Cambodge.",
        "Le noeud {platformName} Phnom Penh est une ligne BGP, et un noeud similaire est : Ho Chi Minh, Vietnam.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Cambodia VPS",
        link: "https://go.lightnode.com/cambodia-vps",
      },
      "zh-CN": {
        title: "柬埔寨VPS",
        link: "https://go.lightnode.com/zh/cambodia-vps",
      },
      "zh-HK": {
        title: "柬埔寨VPS",
        link: "https://go.lightnode.com/zh/cambodia-vps",
      },
      "vi-VN": {
        title: "VPS Campuchia",
        link: "https://go.lightnode.com/cambodia-vps",
      },
      "fr-FR": {
        title: "VPS Cambodge",
        link: "https://go.lightnode.com/cambodia-vps",
      },
    },
  },
  {
    text: "Ho Chi Minh",
    continent: "Asia",
    url: "vietnam-vps",
    value: "10",
    tab: "LightNode",
    countryImg: country8,
    countryImgCode: "vn",
    banner: hochiminhcity,
    position: {
      top: 281,
      left: 799,
    },
    regionCode: "vn-hochiminh-1",
    // country: 'Vietnam',
    city: {
      "en-US": "Ho Chi Minh",
      "zh-CN": "胡志明",
      "zh-HK": "胡志明",
      "vi-VN": "Hồ Chí Minh",
      "fr-FR": "Ho Chi Minh",
    },
    country: {
      "en-US": "Vietnam",
      "zh-CN": "越南",
      "zh-HK": "越南",
      "vi-VN": "Việt Nam",
      "fr-FR": "Vietnam",
    },
    introc: {
      "en-US": [
        "formerly known as Saigons,is the largest city in Vietnam. Located in Southern Vietnam., the city surrounds the Saigon River.",
        "Ho Chi Minh, the largest city in Vietnam now, is located in southern Vietnam.",
        "The {platformName} Ho Chi Minh node is a BGP line, and similar node is: Hanoi.",
      ],
      "zh-CN": [
        "市，以前（现在通常）被称为西贡，是越南最大的城市。这座城市位于越南南部，环绕西贡河。",
        "胡志明市，现是越南最大的城市，这座城市位于越南南部。",
        "{platformName}胡志明市节点为BGP线路，类似的节点有：河内。",
      ],
      "zh-HK": [
        "市，以前（現在通常）被稱為西貢，是越南最大的城市。這座城市位於越南南部，環繞西貢河。",
        "胡志明市，現是越南最大的城市，這座城市位於越南南部。",
        "{platformName}胡志明市節點為BGP線路，類似的節點有：河內。",
      ],
      "vi-VN": [
        "Thành phố, trước đây (bây giờ thường) được gọi là Sài Gòn, là thành phố lớn nhất của Việt Nam. Thành phố này nằm ở miền Nam của Việt Nam, bao quanh sông Sài Gòn.",
        "Thành phố Hồ Chí Minh, hiện là thành phố lớn nhất của Việt Nam, thành phố này nằm ở miền Nam của Việt Nam.",
        "Nút Thành phố Hồ Chí Minh của {platformName} là đường dây BGP, nút tương tự gồm: Hà Nội.",
      ],
      "fr-FR": [
        "Formellement connue sous le nom de Saigon, Saigon est la plus grande ville du Vietnam. Située dans le sud du Vietnam, la ville est entourée par la rivière Saigon.",
        "Ho Chi Minh, la plus grande ville du Vietnam actuellement, est située dans le sud du pays.",
        "Le nœud {platformName} Ho Chi Minh est une ligne BGP, et un nœud similaire est : Hanoi.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Vietnam VPS",
        link: "https://go.lightnode.com/vietnam-vps",
      },
      "zh-CN": {
        title: "越南VPS",
        link: "https://go.lightnode.com/zh/vietnam-vps",
      },
      "zh-HK": {
        title: "越南VPS",
        link: "https://go.lightnode.com/zh/vietnam-vps",
      },
      "vi-VN": {
        title: "VPS Việt Nam",
        link: "https://go.lightnode.com/vietnam-vps",
      },
      "fr-FR": {
        title: "VPS Vietnam",
        link: "https://go.lightnode.com/vietnam-vps",
      },
    },
  },
  {
    text: "Hong Kong",
    continent: "Asia",
    url: "hong-kong-vps",
    value: "11",
    tab: "LightNode",
    countryImg: country6,
    countryImgCode: "cn",
    banner: hongkong,
    position: {
      top: 250,
      left: 812,
    },
    regionCode: "cn-hongkong-3-bgp",
    oldRegionCode: "cn-hongkong-3", //没有区分线路时的regionCode
    lineType: [
      // {
      //   line: "CNCN",
      //   regionCode: "cn-hongkong-3-cn2",
      // },
      {
        line: "BGP",
        regionCode: "cn-hongkong-3-bgp",
      },
    ],
    // country: 'China',
    city: {
      "en-US": "Hong Kong",
      "zh-CN": "香港",
      "zh-HK": "香港",
      "vi-VN": "Hồng Kông",
      "fr-FR": "Hong Kong",
    },
    country: {
      "en-US": "China",
      "zh-CN": "中国",
      "zh-HK": "中國",
      "vi-VN": "Trung Quốc",
      "fr-FR": "Chine",
    },
    introc: {
      "en-US": [
        ", officially the Hong Kong Special Administrative Region of the People's Republic of China (HKSAR). Hong Kong is also one of the three global financial centres and one of the most developed cities in the world.",
        "Hong Kong, whose full name is the Hong Kong Special Administrative Region of the People's Republic of China, is located in southern China.",
        "{platformName} Hong Kong node is a BGP line, similar nodes are: Taiwan, Hanoi, Manila.",
      ],
      "zh-CN": [
        "，正式名称为中华人民共和国香港特别行政区（HKSAR）。 香港是全球三大金融中心之一，世界上最发达的城市之一。",
        "香港全称中华人民共和国香港特别行政区，位于中国南部。",
        "{platformName}香港节点为CNCN和BGP线路，类似的节点有：台北、河内、马尼拉。",
      ],
      "zh-HK": [
        "，正式名稱為中華人民共和國香港特別行政區（HKSAR）。香港也是全球三大金融中心之一，世界上最發達的城市之一。",
        "香港全稱中華人民共和國香港特別行政區，位於中國南部。",
        "{platformName}香港節點為CNCN和BGP線路，類似的節點有：臺北、河內、馬尼拉。",
      ],
      "vi-VN": [
        ", tên chính thức là Đặc khu hành chính Hồng Kông của Nước Cộng hòa Nhân dân Trung Hoa (HKSAR). Hồng Kông là một trong ba trung tâm tài chính lớn của toàn cầu, và là một trong những thành phố phát triển nhất thế giới.",
        "Hồng Kông, tên đầy đủ là Đặc khu hành chính Hồng Kông của Nước Cộng hòa Nhân dân Trung Hoa, nằm ở phía Nam của Trung Quốc.",
        "Nút Hồng Kông của {platformName} là đường dây CNCN và BGP, các nút tương tự gồm: Đài Loan, Hà Nội, Manila.",
      ],
      "fr-FR": [
        ", officiellement la Région Administrative Spéciale de Hong Kong de la République populaire de Chine (HKSAR). Hong Kong est aussi un des trois centres financiers mondiaux et une des villes les plus développées du monde.",
        "Hong Kong, qui se nomme entièrement la Région Administrative Spéciale de Hong Kong de la République populaire de Chine, est située dans le sud de la Chine.",
        "Le noeud {platformName} Hong Kong est une ligne BGP, les noeuds similaires sont : Taiwan, Hanoi, Manille.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Hong Kong VPS",
        link: "https://go.lightnode.com/hong-kong-vps",
      },
      "zh-CN": {
        title: "香港VPS",
        link: "https://go.lightnode.com/zh/hong-kong-vps",
      },
      "zh-HK": {
        title: "香港VPS",
        link: "https://go.lightnode.com/zh/hong-kong-vps",
      },
      "vi-VN": {
        title: "VPS Hồng Kông",
        link: "https://go.lightnode.com/hong-kong-vps",
      },
      "fr-FR": {
        title: "VPS de Hong Kong",
        link: "https://go.lightnode.com/hong-kong-vps",
      },
    },
  },
  {
    text: "Taipei",
    continent: "Asia",
    url: "taiwan-vps",
    value: "12",
    tab: "LightNode",
    countryImg: country6,
    countryImgCode: "cn",
    banner: taipeichina,
    position: {
      top: 243,
      left: 836,
    },
    regionCode: "cn-taiwan-2",
    city: {
      "en-US": "Taipei",
      "zh-CN": "台北",
      "zh-HK": "臺北",
      "vi-VN": "Đài Bắc",
      "fr-FR": "Taipei",
    },
    country: {
      "en-US": "China",
      "zh-CN": "中国",
      "zh-HK": "中國",
      "vi-VN": "Trung Quốc",
      "fr-FR": "Chine",
    },
    introc: {
      "en-US": [
        ", is the capital of taiwan province, people's republic of china.Taipei is the economic, political, educational and cultural center of Taiwan and one of the major hubs in East Asia.",
        "Taipei is the capital of Taiwan Province of the People's Republic of China, located on the southeast coast of China.",
        "{platformName}'s Chinese Taipei node is a BGP line, and similar node is: Hong Kong.",
      ],
      "zh-CN": [
        "，中华人民共和国台湾省省会。台北是台湾的经济、政治、教育和文化中心，也是东亚的主要枢纽之一。",
        "台北是中华人民共和国台湾省省会，位于中国东南沿海。",
        "{platformName}中国台北节点为BGP线路，类似的节点有：香港。",
      ],
      "zh-HK": [
        "，中華人民共和國臺灣省省會。 臺北是台灣的經濟、政治、教育和文化中心，也是東亞的主要樞紐之一。",
        "臺北是中華人民共和國臺灣省省會，位於中國東南沿海。",
        "{platformName}中國台北節點為BGP線路，類似的節點有：香港。",
      ],
      "vi-VN": [
        ", là thủ phủ của tỉnh Đài Loan Nước Cộng hòa Nhân dân Trung Hoa. Đài Bắc là trung tâm kinh tế, chính trị, giáo dục và văn hóa của Đài Loan, cũng là một trong những nút giao chính ở Đông Á.",
        "Đài Bắc là thủ phủ của tỉnh Đài Loan Nước Cộng hòa Nhân dân Trung Hoa, nằm ở ven biển Đông Nam của Trung Quốc.",
        "Nút Đài BắcTrung Quốc của {platformName} là đường dây BGP, nút tương tự gồm: Hồng Kông.",
      ],
      "fr-FR": [
        ", est la capitale de la province de Taïwan de la République populaire de Chine. Taipei représente le centre économique, politique, éducatif et culturel de Taïwan et l'une des principales métropoles d'Asie de l'Est.",
        "Taipei est la capitale de la province de Taiwan de la République populaire de Chine, située sur la côte sud-est de la Chine.",
        "Le nœud Chinese Taipei de {platformName} est une ligne BGP, et un nœud similaire est : Hong Kong.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Taiwan VPS",
        link: "https://go.lightnode.com/taiwan-vps",
      },
      "zh-CN": {
        title: "台湾VPS",
        link: "https://go.lightnode.com/zh/taiwan-vps",
      },
      "zh-HK": {
        title: "台灣VPS",
        link: "https://go.lightnode.com/zh/taiwan-vps",
      },
      "vi-VN": {
        title: "VPS Đài Loan",
        link: "https://go.lightnode.com/taiwan-vps",
      },
      "fr-FR": {
        title: "VPS de Taïwan",
        link: "https://go.lightnode.com/taiwan-vps",
      },
    },
  },
  {
    text: "Seoul",
    continent: "Asia",
    url: "korea-vps",
    value: "13",
    tab: "LightNode VU&DO",
    countryImg: country7,
    countryImgCode: "kr",
    banner: seoul,
    position: {
      top: 209,
      left: 860,
    },
    regionCode: "kr-seoul-1",
    city: {
      "en-US": "Seoul",
      "zh-CN": "首尔",
      "zh-HK": "首爾",
      "vi-VN": "Seoul",
      "fr-FR": "Séoul",
    },
    country: {
      "en-US": "Korea",
      "zh-CN": "韩国",
      "zh-HK": "韓國",
      "vi-VN": "Hàn Quốc",
      "fr-FR": "Corée",
    },
    introc: {
      "en-US": [
        "，officially the Seoul Special City, is the capital and largest metropolis of South Korea.",
        "Seoul is the capital of South Korea, located in the southern part of the Korean Peninsula in East Asia.",
        "{platformName} Seoul node is a BGP line, and similar node is:  Hong Kong.",
      ],
      "zh-CN": [
        "，正式名称为首尔特别市，是韩国的首都和最大的大都市。",
        "首尔是韩国的首都，位于东亚朝鲜半岛南部。",
        "{platformName}首尔节点为BGP线路，类似的节点有：香港。",
      ],
      "zh-HK": [
        "，正式名稱為首爾特別市，是韓國的首都和最大的大都市。",
        "首爾是韓國的首都，位於東亞朝鮮半島南部。",
        "{platformName}首爾節點為BGP線路，類似的節點有：香港。",
      ],
      "vi-VN": [
        ", tên chính thức được gọi là Thành phố đặc biệt Seoul, là thủ đô và là đô thị lớn nhất của Hàn Quốc.",
        "Seoul là thủ đô của Hàn Quốc, nằm ở phía Nam của Bán đảo Triều Tiên ở Đông Á.",
        "Nút Seoul của {platformName} là đường dây BGP, nút tương tự gồm: Hồng Kông.",
      ],
      "fr-FR": [
        ", officiellement la Ville Spéciale de Séoul, est la capitale et la plus grande métropole de la Corée du Sud.",
        "Séoul est la capitale de la Corée du Sud, située dans la partie sud de la péninsule coréenne en Asie de l'Est.",
        "Le noeud {platformName} Seoul est une ligne BGP, et un noeud similaire est : Hong Kong.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Korea VPS",
        link: "https://go.lightnode.com/korea-vps",
      },
      "zh-CN": {
        title: "韩国VPS",
        link: "https://go.lightnode.com/zh/korea-vps",
      },
      "zh-HK": {
        title: "韓國VPS",
        link: "https://go.lightnode.com/zh/korea-vps",
      },
      "vi-VN": {
        title: "VPS Hàn Quốc",
        link: "https://go.lightnode.com/korea-vps",
      },
      "fr-FR": {
        title: "VPS Corée",
        link: "https://go.lightnode.com/korea-vps",
      },
    },
  },
  {
    text: "Johannesburg",
    continent: "Africa",
    url: "south-africa-vps",
    value: "14",
    tab: "LightNode",
    countryImg: country13,
    countryImgCode: "za",
    banner: Johannesburg,
    position: {
      top: 370,
      left: 575,
    },
    regionCode: "sa-johannesburg-1",
    // country: 'South Africa',
    city: {
      "en-US": "Johannesburg",
      "zh-CN": "约翰内斯堡",
      "zh-HK": "約翰內斯堡",
      "vi-VN": "Johannesburg",
      "fr-FR": "Johannesburg",
    },
    country: {
      "en-US": "South Africa",
      "zh-CN": "南非",
      "zh-HK": "南非",
      "vi-VN": "Nam Phi",
      "fr-FR": "Afrique du Sud",
    },
    introc: {
      "en-US": [
        '， informally known as Jozi, Joburg, or "The City of Gold",is the largest city in South Africa, classified as a megacity.',
        "Johannesburg is the provincial capital and largest city of Gauteng, ",
        "{platformName} Johannesburg node is a  BGP line, similar nodes are: Jeddah, Riyadh.",
      ],
      "zh-CN": [
        "，非正式名称为Jozi，Joburg或“黄金之城”是南非最大的城市，被列为特大城市。",
        "约翰内斯堡是南非共和国豪登省省会，也是南非最大的城市。",
        "{platformName}约翰内斯堡节点为BGP线路，类似的节点有：吉达、利雅得。",
      ],
      "zh-HK": [
        "，非正式名稱為Jozi，Joburg或“黃金之城”，是南非最大的城市，被列為特大城市。",
        "約翰內斯堡是南非共和國豪登省省會，也是南非最大的城市。",
        "{platformName}約翰內斯堡節點為BGP線路，類似的節點有：吉達、利雅得。",
      ],
      "vi-VN": [
        ', tên gọi không chính thức là Jozi, Joburg hoặc "Thành phố vàng", là thành phố lớn nhất ở Nam Phi, được xếp vào loại siêu đô thị.',
        "Johannesburg là thủ phủ của tỉnh Gauteng Nước Cộng hòa Nam Phi, cũng là thành phố lớn nhất của Nam Phi.",
        "Nút Johannesburg của {platformName} là đường dây BGP, các nút tương tự gồm: Jeddah, Riyadh.",
      ],
      "fr-FR": [
        ", informellement sous le nom de Jozi, Joburg, ou 'la Ville d'Or',est la plus grande ville d'Afrique du Sud, classée comme une mégalopole.",
        "Johannesburg est la capitale provinciale et la plus grande ville de Gauteng.",
        "Le noeud {platformName} Johannesburg est une ligne BGP, les noeuds similaires sont : Jeddah, Riyadh.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "South Africa VPS",
        link: "https://go.lightnode.com/south-africa-vps",
      },
      "zh-CN": {
        title: "南非VPS",
        link: "https://go.lightnode.com/zh/south-africa-vps",
      },
      "zh-HK": {
        title: "南非VPS",
        link: "https://go.lightnode.com/zh/south-africa-vps",
      },
      "vi-VN": {
        title: "VPS Nam Phi",
        link: "https://go.lightnode.com/south-africa-vps",
      },
      "fr-FR": {
        title: "VPS Afrique du Sud",
        link: "https://go.lightnode.com/south-africa-vps",
      },
    },
  },
  {
    text: "Singapore",
    continent: "Asia",
    url: "singapore-vps",
    value: "15",
    tab: "LightNode VU&DO",
    countryImg: country16,
    countryImgCode: "sg",
    banner: Singapore,
    position: {
      top: 324,
      left: 786,
    },
    regionCode: "sgp-singapore-1",
    // country: 'Republic of Singapore',
    city: {
      "en-US": "Singapore",
      "zh-CN": "新加坡",
      "zh-HK": "新加坡",
      "vi-VN": "Singapore",
      "fr-FR": "Singapour",
    },
    country: {
      "en-US": "Singapore",
      "zh-CN": "新加坡共和国",
      "zh-HK": "新加坡共和國",
      "vi-VN": "Singapore",
      "fr-FR": "Singapour",
    },
    introc: {
      "en-US": [
        ", officially the Republic of Singapore, is a sovereign island city-state in maritime Southeast Asia.",
        "Singapore is a city-state island and city-state in Southeast Asia, located at the southernmost tip of the Indochina Peninsula.",
        "{platformName} Singapore node is a  BGP line, similar nodes are: Phnom Penh, Ho Chi Minh, Bangkok.",
      ],
      "zh-CN": [
        "，正式名称为新加坡共和国，是东南亚海上的一个主权岛屿城邦。",
        "新加坡是位于东南亚的城邦岛国、城市国家，位于中南半岛最南端。",
        "{platformName}新加坡节点为BGP线路，类似的节点有：金边、胡志明市、曼谷。",
      ],
      "zh-HK": [
        "，正式名稱為新加坡共和國，是東南亞海上的一個主權島嶼城邦。",
        "新加坡是位於東南亞的城邦島國、城市國家，位於中南半島最南端。",
        "{platformName}新加坡節點為BGP線路，類似的節點有：金邊、胡志明市、曼谷。",
      ],
      "vi-VN": [
        ", tên gọi chính thức là Nước Cộng hòa Singapore, là một thành bang quốc đảo có chủ quyền ở vùng biển Đông Nam Á.",
        "Singapore là một thành bang quốc đảo, quốc gia thành phố ở Đông Nam Á, nằm ở cực Nam của Bán đảo Đông Dương.",
        "Nút Singapore của {platformName} là đường dây BGP, các nút tương tự gồm: Phnôm Pênh, Thành phố Hồ Chí Minh, Băng Cốc.",
      ],
      "fr-FR": [
        ", officiellement la République de Singapour, est une ville-état souveraine située en Asie du Sud-Est maritime.",
        "Singapour est une ville-île et une cité-état d'Asie du Sud-Est, située à l'extrémité sud de la Péninsule indochinoise.",
        "Le nœud {platformName} Singapore est une ligne BGP, les nœuds similaires sont : Phnom Penh, Ho Chi Minh, Bangkok.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Singapore VPS",
        link: "https://go.lightnode.com/singapore-vps",
      },
      "zh-CN": {
        title: "新加坡VPS",
        link: "https://go.lightnode.com/zh/singapore-vps",
      },
      "zh-HK": {
        title: "新加坡VPS",
        link: "https://go.lightnode.com/zh/singapore-vps",
      },
      "vi-VN": {
        title: "VPS Singapore",
        link: "https://go.lightnode.com/singapore-vps",
      },
      "fr-FR": {
        title: "Singapour VPS",
        link: "https://go.lightnode.com/singapore-vps",
      },
    },
  },
  {
    text: "Manila",
    continent: "Asia",
    value: "16",
    tab: "LightNode",
    url: "philippines-vps",
    countryImg: country17,
    countryImgCode: "ph",
    banner: Manila,
    position: {
      top: 291,
      left: 851,
    },
    regionCode: "ph-manila-1",
    // country: 'Philippines',
    city: {
      "en-US": "Manila",
      "zh-CN": "马尼拉",
      "zh-HK": "馬尼拉",
      "vi-VN": "Manila",
      "fr-FR": "Manille",
    },
    country: {
      "en-US": "Philippines",
      "zh-CN": "菲律宾",
      "zh-HK": "菲律賓",
      "vi-VN": "Philippines",
      "fr-FR": "Philippines",
    },
    introc: {
      "en-US": [
        ", officially the City of Manila，is capital of the Philippines, and its second-most populous city.",
        "Manila is the capital city and largest port of the Philippines, located on the east coast of Manila Bay, the largest island in the Philippines, Luzon Island. ",
        "{platformName} Manila node is a  BGP line, similar nodes are: Ho Chi Minh, Phnom Penh, Singapore.",
      ],
      "zh-CN": [
        "，正式名称为马尼拉市，是菲律宾的首都，也是其人口第二多的城市。",
        "马尼拉是菲律宾的首都城市和最大的港口，位于菲律宾最大岛屿－吕宋岛马尼拉湾东岸。",
        "{platformName}马尼拉节点为BGP线路，类似的节点有：胡志明市、金边、新加坡。",
      ],
      "zh-HK": [
        "，正式名稱為馬尼拉市，是菲律賓的首都，也是其人口第二多的城市。",
        "馬尼拉是菲律賓的首都城市和最大的港口，位於菲律賓最大島嶼－呂宋島馬尼拉灣東岸。",
        "{platformName}馬尼拉節點為BGP線路，類似的節點有：胡志明市、金邊、新加坡。",
      ],
      "vi-VN": [
        ", tên gọi chính thức là Thành phố Manila, là thủ đô của Philippines, cũng là thành phố đông dân thứ hai của Philippines.",
        "Manila là thành phố thủ đô và là cảng lớn nhất của Philippines, nằm ở bờ biển phía Đông của Vịnh Manila trên đảo Luzon, hòn đảo lớn nhất ở Philippines.",
        "Nút Manila của {platformName} là đường dây BGP, các nút tương tự gồm: Thành phố Hồ Chí Minh, Phnôm Pênh, Singapore.",
      ],
      "fr-FR": [
        ", officiellement la ville de Manille, la capitale des Philippines, et sa deuxième ville la plus peuplée.",
        "Manille est la capitale et le plus grand port des Philippines, situé sur la côte est de la Baie de Manille, la plus grande île des Philippines, l'île de Luçon.",
        "Le nœud {platformName} Manille est une ligne BGP, les nœuds similaires sont : Ho Chi Minh, Phnom Penh, Singapour.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Philippines VPS",
        link: "https://go.lightnode.com/philippines-vps",
      },
      "zh-CN": {
        title: "菲律宾VPS",
        link: "https://go.lightnode.com/zh/philippines-vps",
      },
      "zh-HK": {
        title: "菲律賓VPS",
        link: "https://go.lightnode.com/zh/philippines-vps",
      },
      "vi-VN": {
        title: "VPS Philippines",
        link: "https://go.lightnode.com/philippines-vps",
      },
      "fr-FR": {
        title: "VPS Philippines",
        link: "https://go.lightnode.com/philippines-vps",
      },
    },
  },
  {
    text: "Dhaka",
    continent: "Asia",
    url: "bangladesh-vps",
    value: "17",
    tab: "LightNode",
    countryImg: country18,
    countryImgCode: "bd",
    banner: Dhaka,
    position: {
      top: 242,
      left: 748,
    },
    regionCode: "bg-dhaka-1",
    // country: 'Bangladesh',
    city: {
      "en-US": "Dhaka",
      "zh-CN": "达卡",
      "zh-HK": "達卡",
      "vi-VN": "Dhaka",
      "fr-FR": "Dhaka",
    },
    country: {
      "en-US": "Bangladesh",
      "zh-CN": "孟加拉国",
      "zh-HK": "孟加拉國",
      "vi-VN": "Bangladesh",
      "fr-FR": "Bangladesh",
    },
    introc: {
      "en-US": [
        ", is the capital and largest city of Bangladesh, as well as the world's largest Bengali-speaking city. ",
        "Dhaka is the capital and largest city of Bangladesh, located in South Asia.",
        "The {platformName} Dhaka node is a BGP line, similar nodes are: Bangkok, Hanoi.",
      ],
      "zh-CN": [
        ", 是孟加拉国的首都和最大城市，也是世界上最大的孟加拉语城市。",
        "达卡是孟加拉国的首都和最大城市，位于南亚。",
        "{platformName}达卡节点为BGP线路，类似的节点有：曼谷、河内。",
      ],
      "zh-HK": [
        ", 是孟加拉國的首都和最大城市，也是世界上最大的孟加拉文城市。",
        "達卡是孟加拉國的首都和最大城市，位於南亞。",
        "{platformName}達卡節點為BGP線路，類似的節點有：曼谷、河內。",
      ],
      "vi-VN": [
        ", là thủ đô và là thành phố lớn nhất của Bangladesh, cũng là thành phố nói tiếng Bengali lớn nhất trên thế giới.",
        "Dhaka là thủ đô và thành phố lớn nhất của Bangladesh, nằm ở Nam Á.",
        "Nút Dhaka của {platformName} là đường dây BGP, các nút tương tự gồm: Băng Cốc, Hà Nội.",
      ],
      "fr-FR": [
        ", est la capitale et la plus grande ville du Bangladesh, la plus grande ville de langue bengali du monde.",
        "Dhaka est la capitale et la plus grande ville du Bangladesh, située en Asie du Sud.",
        "Le nœud {platformName} Dhaka est une ligne BGP, les nœuds similaires sont : Bangkok, Hanoi.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Bangladesh VPS",
        link: "https://go.lightnode.com/bangladesh-vps",
      },
      "zh-CN": {
        title: "孟加拉VPS",
        link: "https://go.lightnode.com/zh/bangladesh-vps",
      },
      "zh-HK": {
        title: "孟加拉VPS",
        link: "https://go.lightnode.com/zh/bangladesh-vps",
      },
      "vi-VN": {
        title: "VPS Bangladesh",
        link: "https://go.lightnode.com/bangladesh-vps",
      },
      "fr-FR": {
        title: "VPS Bangladesh",
        link: "https://go.lightnode.com/bangladesh-vps",
      },
    },
  },
  {
    text: "Sao Paulo",
    continent: "South America",
    url: "brazil-vps",
    value: "18",
    tab: "LightNode",
    countryImg: country19,
    countryImgCode: "br",
    banner: Saopaulo,
    position: {
      top: 366,
      left: 350,
    },
    regionCode: "br-saopaulo-1",
    // country: 'Brazil',
    city: {
      "en-US": "Sao Paulo",
      "zh-CN": "圣保罗",
      "zh-HK": "聖保羅",
      "vi-VN": "São Paulo",
      "fr-FR": "São Paulo",
    },
    country: {
      "en-US": "Brazil",
      "zh-CN": "巴西",
      "zh-HK": "巴西",
      "vi-VN": "Brazil",
      "fr-FR": "Brésil",
    },
    introc: {
      "en-US": [
        "is a city in the Southeast Region of Brazil.Listed by the GaWC as an alpha global city.Additionally, São Paulo is the largest Portuguese-speaking city in the world. ",
        "San Paulo, the capital of the state of São Paulo in southeastern Brazil, is located in eastern South America.",
        "The {platformName} São Paulo node is a BGP line.",
      ],
      "zh-CN": [
        "是巴西东南部地区的一座城市。圣保罗市被GaWC列为阿尔法全球城市。此外，圣保罗是世界上最大的葡萄牙语城市。",
        "圣保罗，巴西东南部州分圣保罗州首府，位于南美洲东部。",
        "{platformName}圣保罗节点为BGP线路。",
      ],
      "zh-HK": [
        "是巴西東南部地區的一座城市。聖保羅市被GaWC列為阿爾法全球城市，此外，聖保羅是世界上最大的葡萄牙語城市。",
        "聖保羅，巴西東南部州分聖保羅州首府，位於南美洲東部。",
        "{platformName}聖保羅節點為BGP線路。",
      ],
      "vi-VN": [
        "là một thành phố ở khu vực Đông Nam của Brazil. Thành phố São Paulo được GaWC liệt vào thành phố toàn cầu Alpha, ngoài ra, São Paulo là thành phố nói tiếng Bồ Đào Nha lớn nhất trên thế giới.",
        "São Paulo, thủ phủ của bang São Paulo ở Đông Nam Brazil, nằm ở phía Đông của Nam Mỹ.",
        "Nút São Paulo của {platformName} là đường dây BGP.",
      ],
      "fr-FR": [
        "est une ville de la Région sud-est du Brésil, listée par le GaWC comme une ville globale alpha. En outre, Saint-Paul est la plus grande ville de langue portugaise au monde.",
        "São Paulo, la capitale de l'état de São Paulo dans le sud-est du Brésil, est située dans l'est de l'Amérique du Sud.",
        "Le nœud {platformName} São Paulo est une ligne BGP.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Brazil VPS",
        link: "https://go.lightnode.com/brazil-vps",
      },
      "zh-CN": {
        title: "巴西VPS",
        link: "https://go.lightnode.com/zh/brazil-vps",
      },
      "zh-HK": {
        title: "巴西VPS",
        link: "https://go.lightnode.com/zh/brazil-vps",
      },
      "vi-VN": {
        title: "VPS Brazil",
        link: "https://go.lightnode.com/brazil-vps",
      },
      "fr-FR": {
        title: "VPS Brésil",
        link: "https://go.lightnode.com/brazil-vps",
      },
    },
  },
  {
    text: "Jeddah",
    continent: "Asia",
    url: "jeddah-vps",
    value: "19",
    tab: "LightNode",
    countryImg: country11,
    countryImgCode: "sa",
    banner: Jeddah,
    position: {
      top: 263,
      left: 618,
    },
    regionCode: "sau-jeddah-1",
    // country: 'Saudi Arabia',
    city: {
      "en-US": "Jeddah",
      "zh-CN": "吉达",
      "zh-HK": "吉達",
      "vi-VN": "Jeddah",
      "fr-FR": "Jeddah",
    },
    country: {
      "en-US": "Saudi Arabia",
      "zh-CN": "沙特阿拉伯",
      "zh-HK": "沙烏地阿拉伯",
      "vi-VN": "Ả Rập Saudi",
      "fr-FR": "Arabie saoudite",
    },
    introc: {
      "en-US": [
        "is a city in the Hejaz region of Saudi Arabia and the country's commercial center.",
        "Jeddah, a port city in The Mecca Province of Saudi Arabia, is located on the east coast of the Red Sea.",
        "{platformName} Jeddah node is a  BGP line, similar nodes are: Riyadh, Dubai.",
      ],
      "zh-CN": [
        "是沙特阿拉伯汉志地区的一个城市，也是该国的商业中心。",
        "吉达，沙特阿拉伯麦加省一个港口城市，位于红海东岸。",
        "{platformName}吉达节点为BGP线路，类似的节点有：利雅得、迪拜。",
      ],
      "zh-HK": [
        "是沙烏地阿拉伯漢志地區的一個城市，也是該國的商業中心。",
        "吉達，沙烏地阿拉伯麥加省一個港口城市，位於紅海東岸。",
        "{platformName}吉達節點為BGP線路，類似的節點有：利雅得、迪拜。",
      ],
      "vi-VN": [
        "là một thành phố ở vùng Hejaz của Ả Rập Xê Út, cũng là trung tâm thương mại của đất nước này.",
        "Jeddah, là một thành phố cảng ở tỉnh Mecca của Ả Rập Xê Út, nằm ở bờ biển phía Đông của Biển Đỏ.",
        "Nút Jeddah của {platformName} là đường dây BGP, các nút tương tự gồm: Riyadh, Dubai.",
      ],
      "fr-FR": [
        "est une ville de la région du Hejaz en Arabie saoudite et le centre commercial du pays.",
        "Jeddah, ville portuaire de la Province de Mecca en Arabie saoudite, est située sur la côte est de la mer Rouge.",
        "Le noeud {platformName} Jeddah est une ligne BGP, les noeuds similaires sont : Riyadh, Dubai.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Saudi Arabia VPS",
        link: "https://go.lightnode.com/saudi-arabia-vps",
      },
      "zh-CN": {
        title: "沙特阿拉伯VPS",
        link: "https://go.lightnode.com/zh/saudi-arabia-vps",
      },
      "zh-HK": {
        title: "沙烏地阿拉伯VPS",
        link: "https://go.lightnode.com/zh/saudi-arabia-vps",
      },
      "vi-VN": {
        title: "VPS Ả Rập Saudi",
        link: "https://go.lightnode.com/saudi-arabia-vps",
      },
      "fr-FR": {
        title: "VPS Arabie Saoudite",
        link: "https://go.lightnode.com/saudi-arabia-vps",
      },
    },
  },
  {
    text: "Tokyo",
    continent: "Asia",
    url: "japan-vps",
    value: "20",
    tab: "LightNode",
    countryImg: countryTokyo,
    countryImgCode: "jp",
    banner: Tokyo,
    position: {
      top: 225,
      left: 894,
    },
    regionCode: "jp-tokyo-1",
    // country: 'Japan',
    city: {
      "en-US": "Tokyo",
      "zh-CN": "东京",
      "zh-HK": "東京",
      "vi-VN": "Tokyo",
      "fr-FR": "Tokyo",
    },
    country: {
      "en-US": "Japan",
      "zh-CN": "日本",
      "zh-HK": "日本",
      "vi-VN": "Nhật Bản",
      "fr-FR": "Japon",
    },
    introc: {
      "en-US": [
        ", officially the Tokyo Metropolis, is the capital and largest city of Japan. Tokyo is the political and economic center of the country, as well as the seat of the Emperor of Japan and the national government.",
        "Tokyo, the capital and largest city of Japan, is located in East Asia.",
        "The {platformName} Tokyo node is a BGP line, and the similar node is: Seoul.",
      ],
      "zh-CN": [
        "，正式名称为东京都，是日本的首都和最大城市。东京是该国的政治和经济中心，也是日本天皇和国家政府的所在地。",
        "东京，是日本的首都和最大城市，位于东亚。",
        "{platformName}东京节点为BGP线路，类似的节点有：首尔。",
      ],
      "zh-HK": [
        "，正式名稱為東京都，是日本的首都和最大城市。東京是該國的政治和經濟中心，也是日本天皇和國家政府的所在地。",
        "東京，是日本的首都和最大城市，位於東亞。",
        "{platformName}東京節點為BGP線路，類似的節點有：首爾。",
      ],
      "vi-VN": [
        ", tên gọi chính thức là Tokyo Metropolis, là thủ đô và là thành phố lớn nhất của Nhật Bản. Tokyo là trung tâm chính trị và kinh tế của đất nước này, cũng là nơi ngự trị của Nhật Hoàng và chính phủ quốc gia.",
        "Tokyo, là thủ đô và là thành phố lớn nhất của Nhật Bản, nằm ở Đông Á.",
        "Nút Tokyo của {platformName} là đường dây BGP, nút tương tự gồm: Seoul.",
      ],
      "fr-FR": [
        ", officiellement la Métropole de Tokyo, est la capitale et la plus grande ville du Japon. Tokyo est le centre politique et économique du pays, mais aussi le siège de l'empereur du Japon et du gouvernement national.",
        "Tokyo, la capitale et la plus grande ville du Japon, est située en Asie de l'Est.",
        "Le nœud {platformName} Tokyo est une ligne BGP, et le nœud similaire est : Séoul.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Japan VPS",
        link: "https://go.lightnode.com/japan-vps",
      },
      "zh-CN": {
        title: "日本VPS",
        link: "https://go.lightnode.com/zh/japan-vps",
      },
      "zh-HK": {
        title: "日本VPS",
        link: "https://go.lightnode.com/zh/japan-vps",
      },
      "vi-VN": {
        title: "VPS Nhật Bản",
        link: "https://go.lightnode.com/japan-vps",
      },
      "fr-FR": {
        title: "VPS Japon",
        link: "https://go.lightnode.com/japan-vps",
      },
    },
  },
  {
    text: "Cairo",
    continent: "Africa",
    url: "egypt-vps",
    value: "21",
    tab: "LightNode",
    countryImg: countryCairo,
    countryImgCode: "eg",
    banner: Cairo,
    position: {
      top: 243,
      left: 570,
    },
    regionCode: "eg-cairo-1",
    // country: 'Egypt',
    city: {
      "en-US": "Cairo",
      "zh-CN": "开罗",
      "zh-HK": "開羅",
      "vi-VN": "Cairo",
      "fr-FR": "Caire",
    },
    country: {
      "en-US": "Egypt",
      "zh-CN": "埃及",
      "zh-HK": "埃及",
      "vi-VN": "Ai Cập",
      "fr-FR": "Égypte",
    },
    introc: {
      "en-US": [
        ", the capital of Egypt and the largest city in the Arab world. The economy of Cairo was ranked first in the Middle East in 2005",
        "Cairo, the capital of Egypt, is the largest city in the Arab world. Located in northeast Africa.",
        "{platformName} Cairo node is a BGP line, and similar nodes are Turkey, Jeddah, and Riyadh.",
      ],
      "zh-CN": [
        ", 是埃及的首都，也是阿拉伯世界最大的城市。2005年，开罗经济在中东地区排名第一。",
        "开罗，開羅是埃及的首都，也是阿拉伯世界最大的城市。位于非洲东北部。",
        "{platformName}开罗节点为BGP线路，类似的节点有：土耳其、吉达、利雅得。",
      ],
      "zh-HK": [
        ", 是埃及的首都，也是阿拉伯世界最大的城市。2005年，開羅經濟在中東地區排名第一。",
        "開羅，開羅是埃及的首都，也是阿拉伯世界最大的城市。位於非洲東北部。",
        "{platformName}開羅節點為BGP線路，類似的節點有：土耳其、吉達、利雅得。",
      ],
      "vi-VN": [
        ", là thủ đô của Ai Cập, cũng là thành phố lớn nhất trong thế giới Ả Rập. Năm 2005, nền kinh tế Cairo được xếp đứng đầu ở Trung Đông.",
        "Cairo, Cairo là thủ đô của Ai Cập, cũng là thành phố lớn nhất trong thế giới Ả Rập. Nằm ở phía Đông Bắc của Châu Phi.",
        "Nút Cairo của {platformName} là đường dây BGP, các nút tương tự gồm: Thổ Nhĩ Kỳ, Jeddah, Riyadh.",
      ],
      "fr-FR": [
        ", est la capitale de l'Égypte et la plus grande ville du monde arabe. Son économie a été classée première au Moyen-Orient en 2005",
        "Le Caire, capitale de l'Égypte, est la plus grande ville du monde arabe. Située au nord-est de l'Afrique",
        "Le nœud {platformName} du Caire est une ligne BGP, et les nœuds similaires sont la Turquie, Jeddah, et Riyadh.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Egypt VPS",
        link: "https://go.lightnode.com/egypt-vps",
      },
      "zh-CN": {
        title: "埃及VPS",
        link: "https://go.lightnode.com/zh/egypt-vps",
      },
      "zh-HK": {
        title: "埃及VPS",
        link: "https://go.lightnode.com/zh/egypt-vps",
      },
      "vi-VN": {
        title: "VPS Ai Cập",
        link: "https://go.lightnode.com/egypt-vps",
      },
      "fr-FR": {
        title: "VPS Egypte",
        link: "https://go.lightnode.com/egypt-vps",
      },
    },
  },
  {
    text: "Bahrain",
    continent: "Asia",
    url: "bahrain-vps",
    value: "22",
    tab: "LightNode",
    countryImg: countryBahrain,
    countryImgCode: "bh",
    banner: Bahrain,
    position: {
      top: 246,
      left: 592,
    },
    regionCode: "bh-askar-1",
    // country: 'Bahrain',
    city: {
      "en-US": "Bahrain",
      "zh-CN": "巴林",
      "zh-HK": "巴林",
      "vi-VN": "Bahrain",
      "fr-FR": "Bahreïn",
    },
    country: {
      "en-US": "Bahrain",
      "zh-CN": "巴林",
      "zh-HK": "巴林",
      "vi-VN": "Bahrain",
      "fr-FR": "Bahreïn",
    },
    introc: {
      "en-US": [
        ", officially the Kingdom of Bahrain, is an island country in Western Asia. many of the world's largest financial institutions have a presence in the country's capital. It is recognized by the World Bank as a high-income economy. ",
        "Bahrain is located in Asia, West Asia region of a country. ",
        "{platformName} Bahrain node is a BGP line, and similar nodes are Dubai, Riyadh, and Jeddah.",
      ],
      "zh-CN": [
        "，正式名称为巴林王国，是西亚的一个岛国。世界上许多最大的金融机构都在该国首都设有办事处。因此，它被世界银行认定为高收入经济体。",
        "巴林是位于亚洲，西亚地区的一个国家。",
        "{platformName}巴林节点为BGP线路，类似的节点有：迪拜、利雅得、吉达。",
      ],
      "zh-HK": [
        "，正式名稱為巴林王國，是西亞的一個島國。世界上許多最大的金融機構都在該國首都設有辦事處。因此，它被世界銀行認定為高收入經濟體。",
        "巴林是位於亞洲，西亞地區的一個國家。",
        "{platformName}巴林節點為BGP線路，類似的節點有：迪拜、利雅得、吉達。",
      ],
      "vi-VN": [
        ", tên chính thức là Vương quốc Bahrain, là một đảo quốc ở Tây Á. Nhiều tổ chức tài chính lớn nhất thế giới có văn phòng đặt tại thủ đô của nước này. Do đó, nó được Ngân hàng Thế giới công nhận là nền kinh tế có thu nhập cao.",
        "Bahrain là một quốc gia nằm ở Châu Á, Tây Á.",
        "Nút Bahrain của {platformName} là đường dây BGP, các nút tương tự gồm: Dubai, Riyadh, Jeddah.",
      ],
      "fr-FR": [
        ", officiellement le Royaume de Bahreïn, et un pays insulaire d'Asie occidentale. Plusieurs des plus grandes institutions financières du monde sont implantées dans la capitale du pays. Il est considéré par la Banque mondiale comme une économie à revenu élevé.",
        "Bahreïn est situé en Asie, dans la région Asie occidentale d'un pays.",
        "Le nœud {platformName} Bahreïn est une ligne BGP, et les nœuds similaires sont Dubaï, Riyad et Djeddah.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Bahrain VPS",
        link: "https://go.lightnode.com/bahrain-vps",
      },
      "zh-CN": {
        title: "巴林VPS",
        link: "https://go.lightnode.com/zh/bahrain-vps",
      },
      "zh-HK": {
        title: "巴林VPS",
        link: "https://go.lightnode.com/zh/bahrain-vps",
      },
      "vi-VN": {
        title: "VPS Bahrain",
        link: "https://go.lightnode.com/bahrain-vps",
      },
      "fr-FR": {
        title: "VPS de Bahreïn",
        link: "https://go.lightnode.com/bahrain-vps",
      },
    },
  },
  {
    text: "Sofia",
    continent: "Europe",
    url: "bulgaria-vps",
    value: "23",
    tab: "LightNode",
    countryImg: countrySofia,
    countryImgCode: "bg",
    banner: Sofia,
    position: {
      top: 186,
      left: 556,
    },
    regionCode: "bg-sofia-1",
    // country: 'Bulgaria',
    city: {
      "en-US": "Sofia",
      "zh-CN": "索非亚",
      "zh-HK": "索非亞",
      "vi-VN": "Sofia",
      "fr-FR": "Sofia",
    },
    country: {
      "en-US": "Bulgaria",
      "zh-CN": "保加利亚",
      "zh-HK": "保加利亞",
      "vi-VN": "Bulgaria",
      "fr-FR": "Bulgarie",
    },
    introc: {
      "en-US": [
        " is the capital and largest city of Bulgaria. Sofia is the 13th largest city in the European Union, Sofia has been named one of the top ten best places for start-up businesses in the world, especially in information technologies.",
        "Sophia is located in southeastern Europe, Sofia is ranked as Beta- global city by the Globalization and World Cities Research Network.",
        "{platformName} Sofia node is a BGP line, and similar nodes are Istanbul, and Frankfurt.",
      ],
      "zh-CN": [
        "是保加利亚的首都和最大城市。索非亚是欧盟第13大城市，索菲亚被评为世界十大创业企业最佳地点之一，特别是在信息技术方面。",
        "索菲亚位于欧洲东南部，索非亚被全球化和世界城市研究网络评为Beta全球城市。",
        "{platformName}索菲亚节点为BGP线路，类似的节点有：伊斯坦布尔、法兰克福。",
      ],
      "zh-HK": [
        "是保加利亞的首都和最大城市。索非亞是歐盟第13大城市。索菲亞被評為世界十大創業企業最佳地點之一，特別是在信息技術方面。",
        "索菲亞位於歐洲東南部，索非亞被全球化和世界城市研究網絡評為Beta全球城市。",
        "{platformName}索菲亞節點為BGP線路，類似的節點有：伊斯坦布爾、法蘭克福。",
      ],
      "vi-VN": [
        "là thủ đô và là thành phố lớn nhất của Bulgaria. Sofia là thành phố lớn thứ 13 trong Liên minh Châu Âu. Sofia được đánh giá là một trong mười địa điểm tốt nhất cho các công ty khởi nghiệp trên thế giới, đặc biệt là trong lĩnh vực công nghệ thông tin.",
        "Sofia nằm ở phía Đông Nam của Châu Âu, Sofia được Mạng lưới nghiên cứu các thành phố thế giới và toàn cầu hóa đánh giá là thành phố toàn cầu Beta.",
        "Nút Sofia của {platformName} là đường dây BGP, các nút tương tự gồm: Istanbul, Frankfurt.",
      ],
      "fr-FR": [
        "est la capitale et la plus grande ville de Bulgarie. Elle est la 13e plus grande ville de l'Union européenne. Sofia a été désignée comme l'un des dix meilleurs endroits au monde pour les businesses, notamment dans le domaine des technologies de l'information.",
        "Sofia est située dans le sud-est de l'Europe, elle est classée comme une ville bêta-globale par le Réseau de Recherche sur les Villes Mondiales et Globalization.",
        "Le nœud {platformName} Sofia est une ligne BGP, et les nœuds similaires sont Istanbul et Francfort.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Bulgaria VPS",
        link: "https://go.lightnode.com/bulgaria-vps",
      },
      "zh-CN": {
        title: "保加利亚VPS",
        link: "https://go.lightnode.com/zh/bulgaria-vps",
      },
      "zh-HK": {
        title: "保加利亞VPS",
        link: "https://go.lightnode.com/zh/bulgaria-vps",
      },
      "vi-VN": {
        title: "VPS Bulgaria",
        link: "https://go.lightnode.com/bulgaria-vps",
      },
      "fr-FR": {
        title: "VPS Bulgarie",
        link: "https://go.lightnode.com/bulgaria-vps",
      },
    },
  },
  {
    text: "Athens",
    continent: "Europe",
    url: "greece-vps",
    value: "24",
    tab: "LightNode",
    countryImg: countryAthens,
    countryImgCode: "gr",
    banner: Athens,
    position: {
      top: 207,
      left: 538,
    },
    regionCode: "gr-athens-1",
    // country: 'Greece',
    city: {
      "en-US": "Athens",
      "zh-CN": "雅典",
      "zh-HK": "雅典",
      "vi-VN": "Athens",
      "fr-FR": "Athènes",
    },
    country: {
      "en-US": "Greece",
      "zh-CN": "希腊",
      "zh-HK": "希臘",
      "vi-VN": "Hy Lạp",
      "fr-FR": "Grèce",
    },
    introc: {
      "en-US": [
        " is the capital city of Greece, and is a large cosmopolitan metropolis and central to economic, financial, industrial, maritime, political and cultural life in Greece.",
        "Athens is located in Europe. It is the largest city in Greece, and the 3rd largest city in the European, and is one of the biggest economic centers in Southeastern Europe.",
        "{platformName} Sofia node is a BGP line, and similar nodes are Sofia, and Istanbul, Cairo.",
      ],
      "zh-CN": [
        "是希腊的首都，是希腊经济，金融，工业，海事，政治和文化生活的中心。",
        "雅典亚位于欧洲，是希腊最大的城市，也是欧盟第三大城市，是东南欧最大的经济中心之一。",
        "{platformName}雅典节点为BGP线路，类似的节点有：索非亚、伊斯坦布尔、开罗。",
      ],
      "zh-HK": [
        "是希臘的首都，是希臘經濟，金融，工業，海事，政治和文化生活的中心。",
        "雅典亞位於歐洲，是希臘最大的城市，也是歐盟第三大城市，是東南歐最大的經濟中心之一。",
        "{platformName}雅典節點為BGP線路，類似的節點有：索非亞、伊斯坦布爾、開羅。",
      ],
      "vi-VN": [
        "là thủ đô của Hy Lạp, là trung tâm của kinh tế, tài chính, công nghiệp, hàng hải, chính trị và đời sống văn hóa của Hy Lạp.",
        "Athens, nằm ở Châu Âu, là thành phố lớn nhất của Hy Lạp, cùng là thành phố lớn thứ ba trong Liên minh Châu Âu, và là một trong những trung tâm kinh tế lớn nhất ở Đông Nam Âu.",
        "Nút Athens của {platformName} là đường dây BGP, các nút tương tự gồm: Sofia, Istanbul, Cairo.",
      ],
      "fr-FR": [
        "est la capitale de la Grèce, une grande métropole cosmopolite qui occupe une place centrale dans la vie économique, financière, industrielle, maritime, politique et culturelle de la Grèce.",
        "Athènes est située en Europe. Elle est la plus grande ville de Grèce, et la troisième plus grande ville d'Europe, et est l'un des plus grands centres économiques du sud-est de l'Europe.",
        "Le noeud {platformName} Sofia est une ligne BGP, et les noeuds similaires sont Sofia, et Istanbul, Le Caire.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Greece VPS",
        link: "https://go.lightnode.com/greece-vps",
      },
      "zh-CN": {
        title: "希腊VPS",
        link: "https://go.lightnode.com/zh/greece-vps",
      },
      "zh-HK": {
        title: "希臘VPS",
        link: "https://go.lightnode.com/zh/greece-vps",
      },
      "vi-VN": {
        title: "VPS Hy Lạp",
        link: "https://go.lightnode.com/greece-vps",
      },
      "fr-FR": {
        title: "VPS Grèce",
        link: "https://go.lightnode.com/greece-vps",
      },
    },
  },
  {
    text: "Kuala Lumpur",
    continent: "Asia",
    url: "malaysia-vps",
    value: "25",
    tab: "LightNode",
    countryImg: countryLumpur,
    countryImgCode: "my",
    banner: Lumpur,
    position: {
      top: 310,
      left: 776,
    },
    regionCode: "my-kualalumpur-1",
    // country: 'Malaysia',
    city: {
      "en-US": "Kuala Lumpur",
      "zh-CN": "吉隆坡",
      "zh-HK": "吉隆坡",
      "vi-VN": "Kuala Lumpur",
      "fr-FR": "Kuala Lumpur",
    },
    country: {
      "en-US": "Malaysia",
      "zh-CN": "马来西亚",
      "zh-HK": "馬來西亞",
      "vi-VN": "Malaysia",
      "fr-FR": "Malaisie",
    },
    introc: {
      "en-US": [
        ", officially the Federal Territory of Kuala Lumpur and colloquially referred to as KL, is a federal territory and the ceremonial, legislative, and judicial capital city of Malaysia. The city serves as the cultural, financial, and economic centre of Malaysia.",
        "Kuala Lumpur is located in Asia. It is one of the fastest growing cities in Asia and the largest city in Malaysia.",
        "{platformName} Kuala Lumpur node is a BGP line, and similar nodes are Singapore, Manila, Phnom Penh, and Ho Chi Minh.",
      ],
      "zh-CN": [
        "，正式名称为吉隆坡联邦直辖区，俗称KL，是一个联邦直辖区，也是马来西亚的礼仪，立法和司法首都。该市是马来西亚的文化，金融和经济中心。",
        "吉隆坡位于亚洲，它是亚洲发展最快的城市之一，也是马来西亚最大的城市。",
        "{platformName}吉隆坡节点为BGP线路，类似的节点有：新加坡、马尼拉、金边、胡志明。",
      ],
      "zh-HK": [
        "，正式名稱為吉隆坡聯邦直轄區，俗稱KL，是一個聯邦直轄區，也是馬來西亞的禮儀，立法和司法首都。該市是馬來西亞的文化，金融和經濟中心。",
        "吉隆坡位於歐洲，它是亞洲發展最快的城市之一，也是馬來西亞最大的城市。",
        "{platformName}吉隆坡節點為BGP線路，類似的節點有：新加坡、馬尼拉、金邊、胡誌明。",
      ],
      "vi-VN": [
        ", tên gọi chính thức là Lãnh thổ Liên bang Kuala Lumpur, thường được gọi là KL, là một Lãnh thổ Liên bang, cũng là thủ đô nghi lễ, lập pháp và tư pháp của Malaysia. Thành phố này là trung tâm văn hóa, tài chính và kinh tế của Malaysia.",
        "Kuala Lumpur nằm ở Châu Á, đây là một trong những thành phố phát triển nhanh nhất ở Châu Á, cũng là thành phố lớn nhất của Malaysia.",
        "Nút Kuala Lumpur của {platformName} là đường dây BGP, các nút tương tự gồm: Singapore, Manila, Phnom Pênh, Hồ Chí Minh.",
      ],
      "fr-FR": [
        ", officiellement le Territoire Fédéral de Kuala Lumpur et communément appelé KL, est un territoire fédéral et la capitale cérémoniale, législative et judiciaire de la Malaisie. La ville est le centre culturel, financier et économique de la Malaisie.",
        "Kuala Lumpur est située en Asie. Elle est l'une des villes à la croissance la plus rapide d'Asie et la plus grande ville de Malaisie.",
        "{platformName} Kuala Lumpur est une ligne BGP, et les nœuds similaires sont Singapour, Manille, Phnom Penh et Ho Chi Minh.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Malaysia VPS",
        link: "https://go.lightnode.com/malaysia-vps",
      },
      "zh-CN": {
        title: "马来西亚VPS",
        link: "https://go.lightnode.com/zh/malaysia-vps",
      },
      "zh-HK": {
        title: "馬來西亞VPS",
        link: "https://go.lightnode.com/zh/malaysia-vps",
      },
      "vi-VN": {
        title: "VPS Malaysia",
        link: "https://go.lightnode.com/malaysia-vps",
      },
      "fr-FR": {
        title: "VPS Malaisie",
        link: "https://go.lightnode.com/malaysia-vps",
      },
    },
  },
  {
    text: "London",
    continent: "Europe",
    url: "london-vps",
    value: "26",
    tab: "LightNode",
    countryImg: countryLondon,
    countryImgCode: "gb",
    banner: London,
    position: {
      top: 148,
      left: 478,
    },
    regionCode: "gb-london-1",
    // country: 'England',
    city: {
      "en-US": "London",
      "zh-CN": "伦敦",
      "zh-HK": "倫敦",
      "vi-VN": "London",
      "fr-FR": "Londres",
    },
    country: {
      "en-US": "UK",
      "zh-CN": "英国",
      "zh-HK": "英國",
      "vi-VN": "Vương quốc Anh",
      "fr-FR": "Royaume - Uni",
    },
    introc: {
      "en-US": [
        "is the capital and largest city of England and the United Kingdom, located in Europe. As one of the world's major global cities, London exerts a strong influence on its arts, entertainment, fashion, commerce and finance, education, health care, media, science and technology, tourism, and transport and communications, and it is one of the major financial centers in the world. {platformName} London node is a BGP line, and the similar node is Frankfurt.",
      ],
      "zh-CN": [
        "是英格兰和英国的首都和最大城市，位于欧洲。作为世界主要的全球城市之一，伦敦对其艺术、娱乐、时尚、商业和金融、教育、医疗保健、媒体、科学技术、旅游、运输和通信产生了强大的影响，也是世界主要金融中心之一。{platformName}伦敦节点为BGP线路，类似的节点有：法兰克福。",
      ],
      "zh-HK": [
        "是英格蘭和英國的首都和最大城市，位於歐洲。作為世界主要的全球城市之一，倫敦對其藝術、娛樂、時尚、商業和金融、教育、醫療保健、媒體、科學技術、旅遊、運輸和通信產生了強大的影響，也是世界主要金融中心之一。{platformName}倫敦節點為BGP線路，類似的節點有：法蘭克福。",
      ],
      "vi-VN": [
        " là thủ đô và thành phố lớn nhất của Anh và Vương quốc Anh, nằm ở Châu Âu. Là một trong những thành phố toàn cầu lớn trên thế giới, Luân Đôn có ảnh hưởng mạnh mẽ đến nghệ thuật, giải trí, thời trang, thương mại và tài chính, giáo dục, chăm sóc sức khỏe, truyền thông, khoa học và công nghệ, du lịch, giao thông và truyền thông, và đây là một trong những thành phố lớn nhất thế giới. các trung tâm tài chính lớn trên thế giới. Nút {platformName} London là một đường BGP và nút tương tự là Frankfurt.",
      ],
      "fr-FR": [
        " est la capitale et la plus grande ville d'Angleterre et du Royaume-Uni, située en Europe. En tant que l'une des principales villes mondiales, Londres exerce une forte influence sur les arts, le divertissement, la mode, le commerce et la finance, l'éducation, les soins de santé, les médias, la science et la technologie, le tourisme, les transports et les communications, et c'est l'un des grands centres financiers du monde. Le nœud {platformName} London est une ligne BGP, et le nœud similaire est Francfort.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "London VPS",
        link: "https://go.lightnode.com/london-vps",
      },
      "zh-CN": {
        title: "伦敦VPS",
        link: "https://go.lightnode.com/zh/london-vps",
      },
      "zh-HK": {
        title: "倫敦VPS",
        link: "https://go.lightnode.com/zh/london-vps",
      },
      "vi-VN": {
        title: "VPS Luân Đôn",
        link: "https://go.lightnode.com/london-vps",
      },
      "fr-FR": {
        title: "VPS de Londres",
        link: "https://go.lightnode.com/london-vps",
      },
    },
  },
  {
    text: "Muscat",
    continent: "Asia",
    url: "oman-vps",
    value: "27",
    tab: "LightNode",
    countryImg: countryMuscat,
    countryImgCode: "om",
    banner: Muscat,
    position: {
      top: 258,
      left: 650,
    },
    regionCode: "om-muscat-1",
    city: {
      "en-US": "Muscat",
      "zh-CN": "马斯喀特",
      "zh-HK": "馬斯喀特",
      "vi-VN": "Muscat",
      "fr-FR": "Mascate",
    },
    country: {
      "en-US": "Oman",
      "zh-CN": "阿曼",
      "zh-HK": "阿曼",
      "vi-VN": "Oman",
      "fr-FR": "Oman",
    },
    introc: {
      "en-US": [
        " is the capital and most populated city in Oman. Oman, officially the Sultanate of Oman, is an Arabian country located in southwestern Asia. It has oil reserves ranked 22nd globally.  Oman is categorized as a high-income economy and, as of 2022, ranks as the 64th most peaceful country in the world according to the Global Peace Index. {platformName} Muscat node is a BGP line, and similar nodes are Dubai, Bahrain, Riyadh, and Jeddah.",
      ],
      "zh-CN": [
        "是阿曼的首都和人口最多的城市。阿曼正式名称为阿曼苏丹国，是位于亚洲西南部的阿拉伯国家。它的石油储量在全球排名第22位。阿曼被归类为高收入经济体，截至 2022 年，根据全球和平指数，排名世界第 64 位最和平的国家。{platformName}马斯喀特节点为BGP线路，类似的节点有：迪拜、巴林、利雅得和吉达。",
      ],
      "zh-HK": [
        "是阿曼的首都和人口最多的城市。阿曼正式名稱為阿曼蘇丹國，是位於亞洲西南部的阿拉伯國家。它的石油儲量在全球排名第22位。阿曼被歸類為高收入經濟體，截至 2022 年，根據全球和平指數，排名世界第 64 位最和平的國家。{platformName}馬斯喀特節點為BGP線路，類似的節點有：迪拜、巴林、利雅得和吉達。",
      ],
      "vi-VN": [
        "là thủ đô và thành phố đông dân nhất ở Oman. Oman, tên chính thức là Vương quốc Hồi giáo Oman, là một quốc gia Ả Rập nằm ở tây nam châu Á. Nó có trữ lượng dầu đứng thứ 22 trên toàn cầu. Oman được phân loại là nền kinh tế có thu nhập cao và tính đến năm 2022, được xếp hạng là quốc gia hòa bình thứ 64 trên thế giới theo Chỉ số hòa bình toàn cầu. Nút {platformName} Muscat là một dòng BGP và các nút tương tự là Dubai, Bahrain, Riyadh và Jeddah.",
      ],
      "fr-FR": [
        "est la capitale et la ville la plus peuplée d'Oman. Oman, officiellement le Sultanat d'Oman, est un pays arabe situé dans le sud-ouest de l'Asie. Il possède des réserves de pétrole classées au 22e rang mondial. Oman est classé comme une économie à revenu élevé et, à partir de 2022, se classe au 64e rang des pays les plus pacifiques au monde selon l'indice mondial de la paix. Le nœud {platformName} Muscat est une ligne BGP et des nœuds similaires sont Dubaï, Bahreïn, Riyad et Djeddah.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Oman VPS",
        link: "https://go.lightnode.com/oman-vps",
      },
      "zh-CN": {
        title: "阿曼VPS",
        link: "https://go.lightnode.com/zh/oman-vps",
      },
      "zh-HK": {
        title: "阿曼VPS",
        link: "https://go.lightnode.com/zh/oman-vps",
      },
      "vi-VN": {
        title: "VPS Ô-man",
        link: "https://go.lightnode.com/oman-vps",
      },
      "fr-FR": {
        title: "VPS d'Oman",
        link: "https://go.lightnode.com/oman-vps",
      },
    },
  },
  {
    text: "Kuwait City",
    continent: "Asia",
    url: "kuwait-vps",
    value: "28",
    tab: "LightNode",
    countryImg: countryKuwait,
    countryImgCode: "kw",
    banner: Kuwait,
    position: {
      top: 250,
      left: 620,
    },
    regionCode: "kw-kuwait-1",
    city: {
      "en-US": "Kuwait City",
      "zh-CN": "科威特城",
      "zh-HK": "科威特城",
      "vi-VN": "Thành phố Kuwait",
      "fr-FR": "Ville de Koweït",
    },
    // country: 'Kuwait',
    country: {
      "en-US": "Kuwait",
      "zh-CN": "科威特",
      "zh-HK": "科威特",
      "vi-VN": "Kuwait",
      "fr-FR": "Koweït",
    },
    introc: {
      "en-US": [
        " is the capital and largest city of Kuwait. Kuwait, officially the State of Kuwait, is a country in Western Asia. Kuwait is an emirate. Kuwait City is the political, cultural, and economical centre of the emirate. Kuwait is a developing country with a high-income economy, backed by the world's sixth largest oil reserves. The Kuwaiti dinar is the highest valued currency in the world. {platformName} Kuwait City node is a BGP line, and similar nodes are Bahrain, Dubai, Riyadh, Jeddah, and Muscat.",
      ],
      "zh-CN": [
        "是科威特的首都和最大城市。科威特，正式名称为科威特国，是西亚的一个国家。科威特是一个酋长国，科威特城是酋长国的政治、文化和经济中心。科威特是一个拥有高收入经济体的发展中国家，拥有世界第六大石油储备，科威特第纳尔是世界上价值最高的货币。{platformName}科威特城节点为BGP线路，类似的节点有：巴林、迪拜、利雅得、吉达、和马斯喀特。",
      ],
      "zh-HK": [
        "是科威特的首都和最大城市。科威特，正式名稱為科威特國，是西亞的一個國家。科威特是一個酋長國，科威特城是酋長國的政治、文化和經濟中心。科威特是一個擁有高收入經濟體的發展中國家，擁有世界第六大石油儲備，科威特第納爾是世界上價值最高的貨幣。{platformName}科威特城節點為BGP線路，類似的節點有：巴林、迪拜、利雅得、吉達、和馬斯喀特。",
      ],
      "vi-VN": [
        "là thủ đô và thành phố lớn nhất của Kuwait. Kuwait, tên chính thức là Nhà nước Kuwait, là một quốc gia ở Tây Á. Kuwait là một tiểu vương quốc, và Thành phố Kuwait là trung tâm chính trị, văn hóa và kinh tế của tiểu vương quốc. Kuwait là một quốc gia đang phát triển với nền kinh tế có thu nhập cao, có trữ lượng dầu mỏ lớn thứ sáu trên thế giới và đồng Dinar của Kuwait là đồng tiền có giá trị nhất trên thế giới. Thành phố Kuwait {platformName} là đường dây BGP, các nút tương tự bao gồm: Bahrain, Dubai, Riyadh, Jeddah và Muscat.",
      ],
      "fr-FR": [
        " est la capitale et la plus grande ville de l'État du Koweït. Le Koweït, officiellement connu sous le nom d'État du Koweït, est un pays d'Asie occidentale. Le Koweït est un émirat et la ville de Koweït est le centre politique, culturel et économique de l'émirat. Le Koweït est un pays en développement avec une économie à revenu élevé, possède les sixièmes plus grandes réserves de pétrole au monde et le dinar koweïtien est la monnaie la plus précieuse au monde. {platformName} Koweït (ville) est une ligne BGP, et les nœuds similaires sont Bahreïn, Dubaï, Riyad, Djeddah et Mascate.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Kuwait VPS",
        link: "https://go.lightnode.com/kuwait-vps",
      },
      "zh-CN": {
        title: "科威特VPS",
        link: "https://go.lightnode.com/zh/kuwait-vps",
      },
      "zh-HK": {
        title: "科威特VPS",
        link: "https://go.lightnode.com/zh/kuwait-vps",
      },
      "vi-VN": {
        title: "VPS Cô-oét",
        link: "https://go.lightnode.com/kuwait-vps",
      },
      "fr-FR": {
        title: "VPS Koweït",
        link: "https://go.lightnode.com/kuwait-vps",
      },
    },
  },
  {
    text: "Marseille",
    continent: "Europe",
    url: "marseille-vps",
    value: "29",
    tab: "LightNode",
    countryImg: countryMarseille,
    countryImgCode: "fr",
    banner: Marseille,
    position: {
      top: 200,
      left: 496,
    },
    regionCode: "fr-marseille-1",
    city: {
      "en-US": "Marseille",
      "zh-CN": "马赛",
      "zh-HK": "馬賽",
      "vi-VN": "Marseille",
      "fr-FR": "Marseille",
    },
    country: {
      "en-US": "France",
      "zh-CN": "法国",
      "zh-HK": "法國",
      "vi-VN": "Pháp",
      "fr-FR": "France",
    },
    introc: {
      "en-US": [
        "is the prefecture of the French department of Bouches-du-Rhône and capital of the Provence-Alpes-Côte d'Azur region. Situated in the camargue region of southern France, it is located on the coast of the Gulf of Lion, part of the Mediterranean Sea, near the mouth of the Rhône river. Marseille is the second most populous city in France, with 870,731 inhabitants in 2019 (Jan. census). Marseille is located in Europe, {platformName} Marseille node is a BGP line, and similar nodes are Frankfurt, London, Athens, Sofia, and Istanbul.",
      ],
      "zh-CN": [
        "，是法国罗纳河畔布希省的省会，也是普罗旺斯-阿尔卑斯-蔚蓝海岸地区的首府。它位于法国南部的卡马格地区，位于狮子湾沿岸，地中海的一部分，靠近罗纳河口。马赛是法国人口第二多的城市，2019年（1月人口普查）有870，731名居民。马赛位于欧洲，{platformName}马赛节点为BGP线路，类似的节点有：法兰克福、伦敦、雅典、索非亚、伊斯坦布尔。",
      ],
      "zh-HK": [
        "，是法國羅納河畔布希省的省會，也是普羅旺斯-阿爾卑斯-蔚藍海岸地區的首府。它位於法國南部的卡馬格地區，位於獅子灣沿岸，地中海的一部分，靠近羅納河口。馬賽是法國人口第二多的城市，2019年（1月人口普查）有870，731名居民。馬賽位於歐洲，{platformName}馬賽節點為BGP線路，類似的節點有：法蘭克福、倫敦、雅典、索非亞、伊斯坦布爾。",
      ],
      "vi-VN": [
        " là tỉnh của tỉnh Bouches-du-Rhône của Pháp và là thủ phủ của vùng Provence-Alpes-Côte d'Azur, nằm trong vùng camargue của miền nam nước Pháp, nằm bên bờ Vịnh Sư tử, một phần của Biển Địa Trung Hải, gần cửa sông Rhône. Marseille là thành phố đông dân thứ hai ở Pháp, với 870.731 cư dân vào năm 2019 (điều tra dân số tháng 1). Marseille nằm ở Châu Âu, nút {platformName} Marseille là một tuyến BGP, và tương tự Các nút là Frankfurt, London, Athens, Sofia và Istanbul.",
      ],
      "fr-FR": [
        "(Marsiho en provençal) est la principale ville française du littoral méditerranéen de Provence (Sud-Est de la France), chef-lieu du département des Bouches-du-Rhône et préfecture de la région Provence-Alpes-Côte d'Azur. En 2019, Marseille est la deuxième commune la plus peuplée de France avec 870 731 habitants. Marseille est située en Europe, {platformName} Marseille est une ligne BGP, et les nœuds similaires sont Francfort, Londres, Athènes, Sofia, Istanbul.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "France VPS",
        link: "https://go.lightnode.com/france-vps",
      },
      "zh-CN": {
        title: "法国VPS",
        link: "https://go.lightnode.com/zh/france-vps",
      },
      "zh-HK": {
        title: "法國VPS",
        link: "https://go.lightnode.com/zh/france-vps",
      },
      "vi-VN": {
        title: "VPS Pháp",
        link: "https://go.lightnode.com/france-vps",
      },
      "fr-FR": {
        title: "VPS France",
        link: "https://go.lightnode.com/france-vps",
      },
    },
  },
  {
    text: "Karachi",
    continent: "Asia",
    url: "pakistan-vps",
    value: "30",
    tab: "LightNode",
    countryImg: countryKarachi,
    countryImgCode: "pk",
    banner: Karachi,
    position: {
      top: 240,
      left: 688,
    },
    regionCode: "pk-karachi-1",
    city: {
      "en-US": "Karachi",
      "zh-CN": "卡拉奇",
      "zh-HK": "卡拉奇",
      "vi-VN": "Karachi",
      "fr-FR": "Karachi",
    },
    country: {
      "en-US": "Pakistan",
      "zh-CN": "巴基斯坦",
      "zh-HK": "巴基斯坦",
      "vi-VN": "Pakistan",
      "fr-FR": "Pakistan",
    },
    introc: {
      "en-US": [
        " is the most populous city in Pakistan and 12th most populous city in the world, with a population of over 20 million. It is situated at the southern tip of the country along the Arabian Sea coast. It is the former capital of Pakistan and capital of the province of Sindh. Ranked as a beta-global city, it is Pakistan's premier industrial and financial centre. Karachi is located in Asia. {platformName} Kuwait City node is a BGP line, and similar nodes are Dubai, Muscat, Riyadh, Bahrain, and Kuwait City.",
      ],
      "zh-CN": [
        "是巴基斯坦人口最多的城市，也是世界上第12个人口最多的城市，人口超过2000万。它位于该国南端的阿拉伯海沿岸。它是巴基斯坦的前首都和信德省的首府。它被列为贝塔全球城市，是巴基斯坦首屈一指的工业和金融中心。卡拉奇位于亚洲，{platformName}卡拉奇节点为BGP线路，类似的节点有：迪拜、马斯喀特、利雅得、巴林、科威特城。",
      ],
      "zh-HK": [
        "是巴基斯坦人口最多的城市，也是世界上第12個人口最多的城市，人口超過2000萬。它位於該國南端的阿拉伯海沿岸。它是巴基斯坦的前首都和信德省的首府。它被列為貝塔全球城市，是巴基斯坦首屈一指的工業和金融中心。卡拉奇位於亞洲，{platformName}卡拉奇節點為BGP線路，類似的節點有：迪拜、馬斯喀特、利雅得、巴林、科威特城。",
      ],
      "vi-VN": [
        "là thành phố đông dân nhất ở Pakistan và là thành phố đông dân thứ 12 trên thế giới, với dân số hơn 20 triệu người. Nó nằm ở mũi phía nam của đất nước dọc theo bờ biển Ả Rập. Đây là thủ đô cũ của Pakistan và là thủ phủ của tỉnh Sindh. Được xếp hạng là thành phố toàn cầu beta, đây là trung tâm tài chính và công nghiệp hàng đầu của Pakistan. Karachi nằm ở Châu Á. Nút {platformName} Kuwait City là một đường BGP và các nút tương tự là Dubai, Muscat, Riyadh, Bahrain và Thành phố Kuwait.",
      ],
      "fr-FR": [
        "est la ville la plus peuplée du Pakistan et la 12e ville la plus peuplée du monde, avec une population de plus de 20 millions d'habitants. Il est situé à l'extrémité sud du pays, le long de la côte de la mer d'Oman. C'est l'ancienne capitale du Pakistan et capitale de la province du Sind. Classée comme une ville bêta-mondiale, c'est le premier centre industriel et financier du Pakistan. Karachi est situé en Asie. Le nœud {platformName} Kuwait City est une ligne BGP, et des nœuds similaires sont Dubaï, Mascate, Riyad, Bahreïn et Koweït City.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Pakistan VPS",
        link: "https://go.lightnode.com/pakistan-vps",
      },
      "zh-CN": {
        title: "巴基斯坦VPS",
        link: "https://go.lightnode.com/zh/pakistan-vps",
      },
      "zh-HK": {
        title: "巴基斯坦VPS",
        link: "https://go.lightnode.com/zh/pakistan-vps",
      },
      "vi-VN": {
        title: "VPS Pakistan",
        link: "https://go.lightnode.com/pakistan-vps",
      },
      "fr-FR": {
        title: "VPS Pakistan",
        link: "https://go.lightnode.com/pakistan-vps",
      },
    },
  },
  {
    text: "kathmandu",
    continent: "Asia",
    url: "nepal-vps",
    value: "31",
    tab: "LightNode",
    countryImg: countryNepal,
    countryImgCode: "np",
    banner: Nepal,
    position: {
      top: 230,
      left: 728,
    },
    regionCode: "np-kathmandu-1",
    city: {
      "en-US": "kathmandu",
      "zh-CN": "加德满都",
      "zh-HK": "加德滿都",
      "vi-VN": "Kathmandu",
      "fr-FR": "Katmandou",
    },
    country: {
      "en-US": "Nepal",
      "zh-CN": "尼泊尔",
      "zh-HK": "尼泊爾",
      "vi-VN": "Nepal",
      "fr-FR": "Népal",
    },
    introc: {
      "en-US": [
        ", officially Kathmandu Metropolitan City, is the capital and most populous city of Nepal. Kathmandu is and has been for many years the center of Nepal's history, art, culture, and economy. It is the largest metropolitan area located in the Himalayas. {platformName} Kathmandu node is a BGP line, and similar nodes are Dhaka and Karachi.",
      ],
      "zh-CN": [
        "正式名称为加德满都大都会，是尼泊尔的首都和人口最多的城市。多年来，加德满都一直是尼泊尔历史、艺术、文化和经济的中心。它是喜马拉雅山区最大的都市区。{platformName}加德满都节点为BGP线路，类似的节点有达卡和卡拉奇。",
      ],
      "zh-HK": [
        "正式名稱為加德滿都大都會，是尼泊爾的首都和人口最多的城市。多年來，加德滿都一直是尼泊爾歷史、藝術、文化和經濟的中心。它是喜馬拉雅山區最大的都市區。 {platformName}加德滿都節點為BGP線路，類似的節點有達卡和卡拉奇。",
      ],
      "vi-VN": [
        ", tên chính thức là Kathmandu Metropolitan City, là thủ đô và thành phố đông dân nhất của Nepal. Kathmandu đã và đang là trung tâm lịch sử, nghệ thuật, văn hóa và kinh tế của Nepal trong nhiều năm. Đây là khu vực đô thị lớn nhất nằm ở dãy Himalaya. Nút {platformName} Kathmandu là một dòng BGP và các nút tương tự là Dhaka và Karachi.",
      ],
      "fr-FR": [
        ", officiellement Kathmandu Metropolitan City, est la capitale et la ville la plus peuplée du Népal. Katmandou est et a été pendant de nombreuses années le centre de l'histoire, de l'art, de la culture et de l'économie du Népal. C'est la plus grande zone métropolitaine située dans l'Himalaya. Le nœud {platformName} Kathmandu est une ligne BGP, et les nœuds similaires sont Dhaka et Karachi.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Nepal VPS",
        link: "https://go.lightnode.com/nepal-vps",
      },
      "zh-CN": {
        title: "尼泊尔VPS",
        link: "https://go.lightnode.com/zh/nepal-vps",
      },
      "zh-HK": {
        title: "尼泊爾VPS",
        link: "https://go.lightnode.com/zh/nepal-vps",
      },
      "vi-VN": {
        title: "VPS Nepal",
        link: "https://go.lightnode.com/nepal-vps",
      },
      "fr-FR": {
        title: "VPS Népal",
        link: "https://go.lightnode.com/nepal-vps",
      },
    },
  },
  {
    text: "Moscow",
    continent: "Europe",
    url: "russia-vps",
    value: "32",
    tab: "LightNode",
    countryImg: countryRussia,
    countryImgCode: "ru",
    banner: Moscow,
    position: {
      top: 153,
      left: 586,
    },
    regionCode: "ru-moscow-1",
    city: {
      "en-US": "Moscow",
      "zh-CN": "莫斯科",
      "zh-HK": "莫斯科",
      "vi-VN": "Moscow",
      "fr-FR": "Moscou",
    },
    country: {
      "en-US": "Russia",
      "zh-CN": "俄罗斯",
      "zh-HK": "俄羅斯",
      "vi-VN": "Nga",
      "fr-FR": "Russie",
    },
    introc: {
      "en-US": [
        "is the capital and largest city in Russia. Moscow is the most populous city in the whole of Europe, the largest city and metropolitan area in Europe. Moscow is governed as a federal city, where it serves as the political, economic, cultural, and scientific center of Russia and Eastern Europe. As an alpha world city, Moscow has one of the world's largest urban economies. {platformName} Moscow node is a BGP line.",
      ],
      "zh-CN": [
        "是俄罗斯的首都和最大城市。莫斯科是整个欧洲人口最多的城市，欧洲最大的城市和大都市区。莫斯科作为联邦城市进行管理，它是俄罗斯和东欧的政治、经济、文化和科学中心。作为一个阿尔法世界城市，莫斯科拥有世界上最大的城市经济之一。{platformName}莫斯科节点是一条BGP线路。",
      ],
      "zh-HK": [
        "是俄羅斯的首都和最大城市。莫斯科是整個歐洲人口最多的城市，歐洲最大的城市和大都市區。莫斯科作為聯邦城市進行管理，它是俄羅斯和東歐的政治、經濟、文化和科學中心。作為一個阿爾法世界城市，莫斯科擁有世界上最大的城市經濟之一。 {platformName}莫斯科節點是一條BGP線路。",
      ],
      "vi-VN": [
        "là thủ đô và thành phố lớn nhất ở Nga. Moscow là thành phố đông dân nhất trên toàn châu Âu, thành phố lớn nhất và khu vực đô thị ở châu Âu. Moscow được quản lý như một thành phố liên bang, nơi đây đóng vai trò là trung tâm chính trị, kinh tế, văn hóa và khoa học của Nga và Đông Âu. Là một thành phố thế giới alpha, Moscow có một trong những nền kinh tế đô thị lớn nhất thế giới. Nút {platformName} Moscow là một đường BGP.",
      ],
      "fr-FR": [
        "est la capitale et la plus grande ville de Russie. Moscou est la ville la plus peuplée de toute l'Europe, la plus grande ville et la plus grande zone métropolitaine d'Europe. Moscou est gouvernée comme une ville fédérale, où elle sert de centre politique, économique, culturel et scientifique de la Russie et de l'Europe de l'Est. En tant que ville mondiale alpha, Moscou possède l'une des plus grandes économies urbaines du monde. Le nœud {platformName} Moscou est une ligne BGP.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Russia VPS",
        link: "https://go.lightnode.com/russia-vps",
      },
      "zh-CN": {
        title: "俄罗斯VPS",
        link: "https://go.lightnode.com/zh/russia-vps",
      },
      "zh-HK": {
        title: "俄羅斯VPS",
        link: "https://go.lightnode.com/zh/russia-vps",
      },
      "vi-VN": {
        title: "VPS Nga",
        link: "https://go.lightnode.com/russia-vps",
      },
      "fr-FR": {
        title: "VPS Russie",
        link: "https://go.lightnode.com/russia-vps",
      },
    },
  },
  {
    text: "Buenos Aires",
    continent: "South America",
    url: "argentina-vps",
    value: "33",
    tab: "LightNode",
    countryImg: countryArgentina,
    countryImgCode: "ar",
    banner: BuenosAires,
    position: {
      top: 395,
      left: 335,
    },
    regionCode: "ar-buenosaires-1",
    city: {
      "en-US": "Buenos Aires",
      "zh-CN": "布宜诺斯艾利斯",
      "zh-HK": "布宜諾斯艾利斯",
      "vi-VN": "Buenos Aires",
      "fr-FR": "Buenos Aires",
    },
    country: {
      "en-US": "Argentina",
      "zh-CN": "阿根廷",
      "zh-HK": "阿根廷",
      "vi-VN": "Argentina",
      "fr-FR": "Argentine",
    },
    introc: {
      "en-US": [
        "(officially the Autonomous City of Buenos Aires), is the capital and primate city of Argentina. The city is located on the western shore of the Río de la Plata, on South America's southeastern coast. Buenos Aires is classified as an alpha global city, according to the Globalization and World Cities Research Network (GaWC) 2020 ranking. {platformName} Buenos Aires node is a BGP line, and similar node is Sao Paulo.",
      ],
      "zh-CN": [
        "（正式名称为布宜诺斯艾利斯自治市）是阿根廷的首都和灵长类城市。这座城市位于南美洲东南海岸的拉普拉塔河西岸。根据全球化和世界城市研究网络（GaWC）2020年排名，布宜诺斯艾利斯被归类为阿尔法全球城市。{platformName}布宜诺斯艾利斯节点为BGP线路，类似的节点有圣保罗。",
      ],
      "zh-HK": [
        "（正式名稱為布宜諾斯艾利斯自治市）是阿根廷的首都和靈長類城市。這座城市位於南美洲東南海岸的拉普拉塔河西岸。根據全球化和世界城市研究網絡（GaWC）2020年排名，布宜諾斯艾利斯被歸類為阿爾法全球城市。{platformName}布宜諾斯艾利斯節點為BGP線路，類似的節點有聖保羅。",
      ],
      "vi-VN": [
        "(tên chính thức là Thành phố Tự trị Buenos Aires), là thủ đô và thành phố linh trưởng của Argentina. Thành phố nằm trên bờ biển phía tây của Río de la Plata, trên bờ biển phía đông nam của Nam Mỹ. Theo bảng xếp hạng năm 2020 của Mạng lưới Nghiên cứu Toàn cầu hóa và Thành phố Thế giới (GaWC), Buenos Aires được phân loại là thành phố toàn cầu alpha. Nút {platformName} Buenos Aires là một đường BGP và nút tương tự là Sao Paulo.",
      ],
      "fr-FR": [
        "(officiellement la ville autonome de Buenos Aires) est la capitale et la ville principale de l'Argentine. La ville est située sur la rive occidentale du Río de la Plata, sur la côte sud-est de l'Amérique du Sud. Buenos Aires est classée parmi les villes mondiales alpha, selon le classement 2020 du Réseau de recherche sur la mondialisation et les villes mondiales (GaWC). Le nœud {platformName} Buenos Aires est une ligne BGP, et le nœud similaire est Sao Paulo.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Argentina VPS",
        link: "https://go.lightnode.com/argentina-vps",
      },
      "zh-CN": {
        title: "阿根廷VPS",
        link: "https://go.lightnode.com/zh/argentina-vps",
      },
      "zh-HK": {
        title: "阿根廷VPS",
        link: "https://go.lightnode.com/zh/argentina-vps",
      },
      "vi-VN": {
        title: "VPS Argentina",
        link: "https://go.lightnode.com/argentina-vps",
      },
      "fr-FR": {
        title: "VPS Argentine",
        link: "https://go.lightnode.com/argentina-vps",
      },
    },
  },
  {
    text: "Jakarta",
    continent: "Asia",
    url: "indonesia-vps",
    value: "34",
    tab: "LightNode",
    countryImg: countryJakarta,
    countryImgCode: "id",
    banner: Indonesia,
    position: {
      top: 342,
      left: 848,
    },
    regionCode: "id-jakarta-1",
    city: {
      "en-US": "Jakarta",
      "zh-CN": "雅加达",
      "zh-HK": "雅加達",
      "vi-VN": "Jakarta",
      "fr-FR": "Jakarta",
    },
    country: {
      "en-US": "Indonesia",
      "zh-CN": "印度尼西亚",
      "zh-HK": "印度尼西亞",
      "vi-VN": "Indonésie",
      "fr-FR": "Indonésie",
    },
    introc: {
      "en-US": [
        ", officially the Special Capital Region of Jakarta, is the capital and largest city of Indonesia. Lying on the northwest coast of Java, the world's most populous island, Jakarta is the largest city in Southeast Asia and serves as the diplomatic capital of ASEAN. The city is the economic, cultural, and political center of Indonesia. {platformName} Jakarta node is a BGP line, and similar nodes are Singapore and Kuala Lumpur.",
      ],
      "zh-CN": [
        "，正式名称为雅加达特别首都地区，是印度尼西亚的首都和最大城市。雅加达位于世界上人口最多的岛屿爪哇岛的西北海岸，是东南亚最大的城市，也是东盟的外交首都。这座城市是印度尼西亚的经济、文化和政治中心。{platformName}雅加达节点为BGP线路，类似的节点有新加坡和吉隆坡。",
      ],
      "zh-HK": [
        "，正式名稱為雅加達特別首都地區，是印度尼西亞的首都和最大城市。雅加達位於世界上人口最多的島嶼爪哇島的西北海岸，是東南亞最大的城市，也是東盟的外交首都。這座城市是印度尼西亞的經濟、文化和政治中心。{platformName}雅加達節點為BGP線路，類似的節點有新加坡和吉隆坡。",
      ],
      "vi-VN": [
        ", chính thức la Région Capitale Spéciale de Jakarta, est la capitale et la plus grande ville d'Indonésie. Située sur la côte nord-ouest de Java, l'île la plus peuplée du monde, Jakarta est la plus grande ville d'Asie du Sud-Est et sert de Capitale Diplomatique à l'ANASE. La ville est le center économique, culturel et politique de l'Indonésie. Le nœud {platformName} Jakarta est une ligne BGP, et les nœuds similaires sont Singapour et Kuala Lumpur.",
      ],
      "fr-FR": [
        ", officiellement la Région Capitale Spéciale de Jakarta, est la capitale et la plus grande ville d'Indonésie. Située sur la côte nord-ouest de Java, l'île la plus peuplée du monde, Jakarta est la plus grande ville d'Asie du Sud-Est et sert de capitale diplomatique à l'ANASE. La ville est le centre économique, culturel et politique de l'Indonésie. Le nœud {platformName} Jakarta est une ligne BGP, et les nœuds similaires sont Singapour et Kuala Lumpur.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Indonesia VPS",
        link: "https://go.lightnode.com/indonesia-vps",
      },
      "zh-CN": {
        title: "印度尼西亚VPS",
        link: "https://go.lightnode.com/zh/indonesia-vps",
      },
      "zh-HK": {
        title: "印度尼西亞VPS",
        link: "https://go.lightnode.com/zh/indonesia-vps",
      },
      "vi-VN": {
        title: "VPS Indonesia",
        link: "https://go.lightnode.com/indonesia-vps",
      },
      "fr-FR": {
        title: "VPS Indonésie",
        link: "https://go.lightnode.com/indonesia-vps",
      },
    },
  },
  {
    text: "Mexico City",
    continent: "North America",
    url: "mexico-vps",
    value: "35",
    tab: "LightNode",
    countryImg: countryMexico,
    countryImgCode: "mx",
    banner: MexicoCity,
    position: {
      top: 264,
      left: 188,
    },
    regionCode: "mx-mexico-1",
    city: {
      "en-US": "Mexico City",
      "zh-CN": "墨西哥城",
      "zh-HK": "墨西哥城",
      "vi-VN": "Thành phố Mexico",
      "fr-FR": "Mexico City",
    },
    country: {
      "en-US": "Mexico",
      "zh-CN": "墨西哥",
      "zh-HK": "墨西哥",
      "vi-VN": "Mexico",
      "fr-FR": "Mexique",
    },
    introc: {
      "en-US": [
        "is the capital and largest city of Mexico, and the most populous city in North America. One of the world's alpha cities, it is located in the Valley of Mexico. Mexico City is the greatest contributor to the country's industrial GDP and also the greatest contributor to the country's GDP in the service sector. Mexico City is one of the most important economic hubs in Latin America. The {platformName} Mexico City node is a BGP line.",
      ],
      "zh-CN": [
        "是墨西哥的首都和最大城市，也是北美人口最多的城市，世界阿尔法城市之一，它位于墨西哥中部高原内的墨西哥谷。墨西哥城是该国工业GDP的最大贡献者，也是该国服务业GDP的最大贡献者，墨西哥城是拉丁美洲最重要的经济中心之一。{platformName}墨西哥城节点为BGP线路。",
      ],
      "zh-HK": [
        "是墨西哥的首都和最大城市，也是北美人口最多的城市，世界阿爾法城市之一，它位於墨西哥中部高原內的墨西哥谷。墨西哥城是該國工業GDP的最大貢獻者，也是該國服務業GDP的最大貢獻者，墨西哥城是拉丁美洲最重要的經濟中心之一。{platformName}墨西哥城節點為BGP線路。",
      ],
      "vi-VN": [
        "là thủ đô và thành phố lớn nhất của Mexico, đồng thời là thành phố đông dân nhất ở Bắc Mỹ. Một trong những thành phố alpha của thế giới, nó nằm ở Thung lũng Mexico. Thành phố Mexico là thành phố đóng góp lớn nhất cho GDP công nghiệp của đất nước và cũng là thành phố đóng góp lớn nhất cho GDP của đất nước trong lĩnh vực dịch vụ. Thành phố Mexico là một trong những trung tâm kinh tế quan trọng nhất ở Mỹ Latinh. Nút {platformName} Mexico City là một đường BGP.",
      ],
      "fr-FR": [
        "est la capitale et la plus grande ville du Mexique, et la ville la plus peuplée d'Amérique du Nord. C'est l'une des villes les plus importantes au monde, située dans la vallée du Mexique. La ville de Mexico est la plus grande contributrice au PIB industriel du pays et également la plus grande contributrice au PIB du pays dans le secteur des services. Mexico est l'un des centres économiques les plus importants d'Amérique latine. Le nœud {platformName} Mexico est une ligne BGP.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Mexico VPS",
        link: "https://go.lightnode.com/mexico-vps",
      },
      "zh-CN": {
        title: "墨西哥VPS",
        link: "https://go.lightnode.com/zh/mexico-vps",
      },
      "zh-HK": {
        title: "墨西哥VPS",
        link: "https://go.lightnode.com/zh/mexico-vps",
      },
      "vi-VN": {
        title: "VPS Mexico",
        link: "https://go.lightnode.com/mexico-vps",
      },
      "fr-FR": {
        title: "VPS Mexique",
        link: "https://go.lightnode.com/mexico-vps",
      },
    },
  },
  {
    text: "Santiago",
    continent: "South America",
    url: "chile-vps",
    value: "36",
    tab: "LightNode",
    countryImg: countryChile,
    countryImgCode: "cl",
    banner: Santiago,
    position: {
      top: 413,
      left: 278,
    },
    regionCode: "cl-santiago-1",
    city: {
      "en-US": "Santiago",
      "zh-CN": "圣地亚哥",
      "zh-HK": "聖地亞哥",
      "vi-VN": "Santiago",
      "fr-FR": "Santiago",
    },
    country: {
      "en-US": "Chile",
      "zh-CN": "智利",
      "zh-HK": "智利",
      "vi-VN": "Chile",
      "fr-FR": "Chili",
    },
    introc: {
      "en-US": [
        ", also known as Santiago de Chile, is the capital and largest city of Chile and one of the largest cities in the Americas. It is located in the country's central valley and is the center of the Santiago Metropolitan Region. Santiago is the political and financial center of Chile and is home to the regional headquarters of many multinational corporations. The {platformName} Santiago node is a BGP line, similar nodes are in Buenos Aires and Sao Paulo.",
      ],
      "zh-CN": [
        "，是智利的首都和最大城市，也是美洲最大的城市之一。它位于该国的中央山谷，是圣地亚哥大都会区的中心。圣地亚哥是智利的政治和金融中心，也是许多跨国公司的地区总部所在地。{platformName}圣地亚哥节点为BGP线路，类似的节点有布宜诺斯艾利斯和圣保罗。",
      ],
      "zh-HK": [
        "，是智利的首都和最大城市，也是美洲最大的城市之一。它位於該國的中央山谷，是聖地亞哥大都會區的中心。聖地亞哥是智利的政治和金融中心，也是許多跨國公司的地區總部所在地。{platformName}聖地亞哥節點為BGP線路，類似的節點有布宜諾斯艾利斯和聖保羅。",
      ],
      "vi-VN": [
        ", còn được gọi là Santiago de Chile, là thủ đô và thành phố lớn nhất của Chile và là một trong những thành phố lớn nhất ở châu Mỹ. Nó nằm ở thung lũng trung tâm của đất nước và là trung tâm của Vùng đô thị Santiago. Santiago là trung tâm chính trị và tài chính của Chile và là nơi đặt trụ sở khu vực của nhiều tập đoàn đa quốc gia. Nút {platformName} Santiago là một đường BGP, các nút tương tự ở Buenos Aires và Sao Paulo.",
      ],
      "fr-FR": [
        ", également connue sous le nom de Santiago du Chili, est la capitale et la plus grande ville du Chili et l'une des plus grandes villes des Amériques. Elle est située dans la vallée centrale du pays et constitue le centre de la région métropolitaine de Santiago. Santiago est le centre politique et financier du Chili et abrite les sièges régionaux de nombreuses multinationales. Le nœud {platformName} Santiago est une ligne BGP, des nœuds similaires se trouvent à Buenos Aires et Sao Paulo.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Chile VPS",
        link: "https://go.lightnode.com/chile-vps",
      },
      "zh-CN": {
        title: "智利VPS",
        link: "https://go.lightnode.com/zh/chile-vps",
      },
      "zh-HK": {
        title: "智利VPS",
        link: "https://go.lightnode.com/zh/chile-vps",
      },
      "vi-VN": {
        title: "VPS Chilê",
        link: "https://go.lightnode.com/chile-vps",
      },
      "fr-FR": {
        title: "Chili VPS",
        link: "https://go.lightnode.com/chile-vps",
      },
    },
  },
  {
    text: "Yangon",
    continent: "Asia",
    url: "myanmar-vps",
    value: "37",
    tab: "LightNode",
    countryImg: countryMyanmar,
    countryImgCode: "mm",
    banner: Yangon,
    position: {
      top: 260,
      left: 770,
    },
    regionCode: "mm-yangon-1",
    city: {
      "en-US": "Yangon",
      "zh-CN": "仰光",
      "zh-HK": "仰光",
      "vi-VN": "Yangon",
      "fr-FR": "Yangon",
    },
    country: {
      "en-US": "Myanmar",
      "zh-CN": "缅甸",
      "zh-HK": "緬甸",
      "vi-VN": "Myanmar",
      "fr-FR": "Myanmar",
    },
    introc: {
      "en-US": [
        "is the capital of the Yangon Region and the largest city of Myanmar (also known as Burma). With over 7 million people, Yangon is Myanmar's most populous city and its most important commercial centre. Yangon is the country's main center for trade, industry, real estate, media, entertainment and tourism. The city represents about one fifth of the national economy. {platformName} Rangoon is a BGP line, similar nodes are Bangkok and Dhaka.",
      ],
      "zh-CN": [
        "是仰光地区的首府，也是缅甸的最大城市。仰光拥有超过7万人口，是缅甸人口最多的城市，也是最重要的商业中心。仰光是该国主要的贸易、工业、房地产、媒体、娱乐和旅游中心。该市约占国民经济的五分之一。{platformName}仰光节点为BGP线路，类似的节点有曼谷和达卡。",
      ],
      "zh-HK": [
        "是仰光地區的首府，也是緬甸的最大城市。仰光擁有超過7萬人口，是緬甸人口最多的城市，也是最重要的商業中心。仰光是該國主要的貿易、工業、房地產、媒體、娛樂和旅遊中心。該市約佔國民經濟的五分之一。{platformName}仰光節點為BGP線路，類似的節點有曼谷和達卡。",
      ],
      "vi-VN": [
        "là thủ phủ của Vùng Yangon và là thành phố lớn nhất của Myanmar. Với hơn 7 triệu dân, Yangon là thành phố đông dân nhất và là trung tâm thương mại quan trọng nhất của Myanmar. Yangon là trung tâm thương mại, công nghiệp, bất động sản, truyền thông, giải trí và du lịch chính của đất nước. Thành phố đại diện cho khoảng một phần năm nền kinh tế quốc gia. {platformName} Rangoon là một dòng BGP, các nút tương tự là Bangkok và Dhaka.",
      ],
      "fr-FR": [
        "est la capitale de la région de Yangon et la plus grande ville du Myanmar. Avec plus de 7 millions d'habitants, Yangon est la ville la plus peuplée du Myanmar et son centre commercial le plus important. Yangon est le principal centre de commerce, d'industrie, d'immobilier, de médias, de divertissement et de tourisme du pays. La ville représente environ un cinquième de l'économie nationale. {platformName} Rangoon est une ligne BGP, les nœuds similaires sont Bangkok et Dhaka.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Myanmar VPS",
        link: "https://go.lightnode.com/myanmar-vps",
      },
      "zh-CN": {
        title: "缅甸VPS",
        link: "https://go.lightnode.com/zh/myanmar-vps",
      },
      "zh-HK": {
        title: "緬甸VPS",
        link: "https://go.lightnode.com/zh/myanmar-vps",
      },
      "vi-VN": {
        title: "VPS Myanmar",
        link: "https://go.lightnode.com/myanmar-vps",
      },
      "fr-FR": {
        title: "VPS Birmanie",
        link: "https://go.lightnode.com/myanmar-vps",
      },
    },
  },
  {
    text: "Baghdad",
    continent: "Asia",
    url: "iraq-vps",
    value: "38",
    tab: "LightNode",
    countryImg: conuntryIraq,
    banner: Baghdad,
    position: {
      top: 214,
      left: 610,
    },
    regionCode: "iq-baghdad-1",
    countryImgCode: "iq",
    city: {
      "en-US": "Baghdad",
      "zh-CN": "巴格达",
      "zh-HK": "巴格達",
      "vi-VN": "Baghdad",
      "fr-FR": "Baghdad",
    },
    country: {
      "en-US": "Iraq",
      "zh-CN": "伊拉克",
      "zh-HK": "伊拉克",
      "vi-VN": "Iraq",
      "fr-FR": "Irak",
    },
    introc: {
      "en-US": [
        " is the capital and largest city of Iraq, a country in the Middle East. The country is bordered by Turkey to the north, Iran to the east, the Persian Gulf and Kuwait to the southeast, Saudi Arabia to the south, Jordan to the southwest and Syria to the west. The LightNode Baghdad node is a BGP line, and similar nodes are Kuwait City.",
      ],
      "zh-CN": [
        "是伊拉克的首都和最大的城市，伊拉克是中东的一个国家。该国北部与土耳其接壤，东部与伊朗接壤，东南部与波斯湾和科威特接壤，南部与沙特阿拉伯接壤，西南部与约旦接壤，西部与叙利亚接壤。LightNode巴格达节点为BGP线路，类似的节点有科威特城。",
      ],
      "zh-HK": [
        "是伊拉克的首都和最大的城市，伊拉克是中東的一個國家。該國北部與土耳其接壤，東部與伊朗接壤，東南部與波斯灣和科威特接壤，南部與沙特阿拉伯接壤，西南部與約旦接壤，西部與敘利亞接壤。LightNode巴格達節點為BGP線路，類似的節點有科威特城。",
      ],
      "vi-VN": [
        " là thủ đô và thành phố lớn nhất của Iraq, một quốc gia ở Trung Đông. Quốc gia này giáp Thổ Nhĩ Kỳ ở phía bắc, Iran ở phía đông, Vịnh Ba Tư và Kuwait ở phía đông nam, Ả Rập Saudi ở phía nam, Jordan ở phía nam. tây nam và Syria ở phía tây. Nút LightNode Baghdad là một tuyến BGP và các nút tương tự là Thành phố Kuwait.",
      ],
      "fr-FR": [
        " est la capitale et la plus grande ville de l'Irak, un pays du Moyen-Orient bordé par la Turquie au nord, l'Iran à l'est, le golfe Persique et le Koweït au sud-est, l'Arabie saoudite au sud et la Jordanie au sud. au sud-ouest et à l'ouest en Syrie. Le nœud LightNode Bagdad est une ligne BGP et les nœuds similaires sont Koweït City.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Iraq VPS",
        link: "https://go.lightnode.com/iraq-vps",
      },
      "zh-CN": {
        title: "伊拉克VPS",
        link: "https://go.lightnode.com/zh/iraq-vps",
      },
      "zh-HK": {
        title: "伊拉克VPS",
        link: "https://go.lightnode.com/zh/iraq-vps",
      },
      "vi-VN": {
        title: "VPS Iraq",
        link: "https://go.lightnode.com/iraq-vps",
      },
      "fr-FR": {
        title: "VPS Irak",
        link: "https://go.lightnode.com/iraq-vps",
      },
    },
  },
  {
    text: "Bogota",
    continent: "South America",
    url: "colombia-vps",
    value: "39",
    tab: "LightNode",
    countryImg: conuntryColombia,
    countryImgCode: "co",
    banner: Bogota,
    position: {
      top: 320,
      left: 256,
    },
    regionCode: "co-bogota-2",
    city: {
      "en-US": "Bogota",
      "zh-CN": "波哥大",
      "zh-HK": "波哥大",
      "vi-VN": "Bogota",
      "fr-FR": "Bogota",
    },
    country: {
      "en-US": "Colombia",
      "zh-CN": "哥伦比亚",
      "zh-HK": "哥倫比亞",
      "vi-VN": "Colombie",
      "fr-FR": "Colombia",
    },
    introc: {
      "en-US": [
        ", officially Bogotá, Distrito Capital, abbreviated Bogotá, D.C., and formerly known as Santa Fe de Bogotá, is the capital city of Colombia, and one of the largest cities in the world. It is the political, economic, administrative, and industrial center of the country. Bogota is located in South America, LightNode Bogota node is a BGP line, and similar nodes are Mexico City and Sao Paulo.",
      ],
      "zh-CN": [
        "，正式名称为波哥大，首都区，缩写为哥伦比亚特区波哥大，原名波哥大圣达菲，是哥伦比亚的首都，也是世界上最大的城市之一。它是该国的政治，经济，行政和工业中心。波哥大位于南美洲，LightNode波哥大节点为BGP线路，类似的节点有墨西哥城和圣保罗。",
      ],
      "zh-HK": [
        "，正式名稱為波哥大，首都區，縮寫為哥倫比亞特區波哥大，原名波哥大聖達菲，是哥倫比亞的首都，也是世界上最大的城市之一。 它是該國的政治，經濟，行政和工業中心。 波哥大位於南美洲，LightNode波哥大節點為BGP線路，類似的節點有墨西哥城和聖保羅。",
      ],
      "vi-VN": [
        ", chính thức là Bogotá, Distrito Capital, viết tắt Bogotá, D.C., và trước đây gọi là Santa Fe de Bogotá, là thành phố thủ đô của Colombia và là một trong những thành phố lớn nhất thế giới. Đây là trung tâm chính trị, kinh tế, hành chính và công nghiệp của đất nước. Bogota nằm ở Nam Mỹ, nút LightNode Bogota là một tuyến BGP và các nút tương tự là Mexico City và Sao Paulo.",
      ],
      "fr-FR": [
        ", officiellement Bogotá, Distrito Capital, en abrégé Bogotá, D.C., et anciennement connue sous le nom de Santa Fe de Bogotá, est la capitale de la Colombie et l'une des plus grandes villes du monde. C'est le centre politique, économique, administratif et industriel du pays. Bogota est située en Amérique du Sud, le nœud LightNode Bogota est une ligne BGP et les nœuds similaires sont Mexico et Sao Paulo.",
      ],
    },
    tagLink: {
      "en-US": {
        title: "Colombia VPS",
        link: "https://go.lightnode.com/colombia-vps",
      },
      "zh-CN": {
        title: "哥伦比亚VPS",
        link: "https://go.lightnode.com/zh/colombia-vps",
      },
      "zh-HK": {
        title: "哥倫比亞VPS",
        link: "https://go.lightnode.com/zh/colombia-vps",
      },
      "vi-VN": {
        title: "VPS Colombia",
        link: "https://go.lightnode.com/colombia-vps",
      },
      "fr-FR": {
        title: "VPS Colombie",
        link: "https://go.lightnode.com/colombia-vps",
      },
    },
  },
  {
    text: "Doha",
    continent: "Asia",
    value: "40",
    tab: "LightNode",
    banner: Doha,
    position: {
      top: 240,
      left: 650,
    },
    regionCode: "qa-doha-1",
    countryImgCode: "qa",
    city: {
      "en-US": "Doha",
      "zh-CN": "多哈",
      "zh-HK": "多哈",
      "vi-VN": "Doha",
      "fr-FR": "Doha",
    },
    country: {
      "en-US": "Qatar",
      "zh-CN": "卡塔尔",
      "zh-HK": "卡塔爾",
      "vi-VN": "Qatar",
      "fr-FR": "Qatar",
    },
    introc: {
      "en-US": [
        "Doha is the capital city and main financial hub of Qatar. Located on the Persian Gulf coast in the east of the country. It is also Qatar's fastest-growing city, with over 80% of the nation's population living in Doha or its surrounding suburbs, known collectively as the Doha Metropolitan Area. Doha is located in West Asia. The LightNode Doha node is a BGP line. Similar nodes include Bahrain and Dubai.	",
      ],
      "zh-CN": [
        "多哈是卡塔尔的首都和主要金融中心, 它位于该国东部的波斯湾沿岸, 它也是卡塔尔发展最快的城市，全国超过80%的人口居住在多哈或其周边郊区，统称为多哈大都会区。多哈位于西亚地区，LightNode多哈节点为BGP线路，类似的节点有巴林和迪拜。",
      ],
      "zh-HK": [
        "多哈是卡塔爾的首都和主要金融中心, 它位於該國東部的波斯灣沿岸, 它也是卡塔爾發展最快的城市，全國超過80%的人口居住在多哈或其周邊郊區，統稱為多哈大都會區。多哈位於西亞地區，LightNode多哈節點為BGP線路，類似的節點有巴林和迪拜。",
      ],
      "vi-VN": [
        "Doha là thủ đô và trung tâm tài chính chính của Qatar. Nằm trên bờ biển Vịnh Ba Tư ở phía đông của đất nước. Đây cũng là thành phố phát triển nhanh nhất của Qatar, với hơn 80% dân số cả nước sống ở Doha hoặc các vùng ngoại ô xung quanh, được gọi chung là Khu vực đô thị Doha. Doha nằm ở Tây Á. Nút LightNode Doha là một dòng BGP. Các nút tương tự bao gồm Bahrain và Dubai.",
      ],
      "fr-FR": [
        "Doha est la capitale et le principal centre financier du Qatar. Situé sur la côte du golfe Persique, à l’est du pays. C'est également la ville du Qatar qui connaît la croissance la plus rapide, avec plus de 80 % de la population du pays vivant à Doha ou dans ses banlieues environnantes, connues collectivement sous le nom de zone métropolitaine de Doha. Doha est située en Asie occidentale. Le nœud LightNode Doha est une ligne BGP. Des nœuds similaires incluent Bahreïn et Dubaï.",
      ],
    },
  },
  // (首页没有)
  {
    text: "Japan",
    continent: "Asia",
    value: "999",
    tab: "VU&DO",
    countryImg: countryTokyo,
    countryImgCode: "jp",
    position: {
      top: 225,
      left: 894,
    },
    city: {
      "en-US": "Japan",
      "zh-CN": "日本",
      "zh-HK": "日本",
      "vi-VN": "Nhật Bản",
      "fr-FR": "Japon",
    },
    country: {
      "en-US": "Japan",
      "zh-CN": "日本",
      "zh-HK": "日本",
      "vi-VN": "Nhật Bản",
      "fr-FR": "Japon",
    },
  },
];
//
interface params {
  key: "continent" | "tab";
  value: string;
}
export const getParentData = (p: params[]) => {
  const arr: nodeType[] = [];
  nodeMapData.forEach((v: nodeType) => {
    if (Object.prototype.toString.call(p) === "[object Array]") {
      if (
        p?.every((m: params) => {
          return v?.[m.key]?.includes(m.value);
        })
      ) {
        arr.push(v);
      }
    }
  });
  return arr;
};
export const getLocation = () => {
  const areaList = [
    {
      text: "Asia",
      value: "Asia",
      children: [],
    },
    {
      text: "Europe",
      value: "Europe",
      children: [],
    },
    {
      text: "NorthAmerica",
      value: "North America",
      children: [],
    },
    {
      text: "Africa",
      value: "Africa",
      children: [],
    },
    {
      text: "SouthAmerica",
      value: "South America",
      children: [],
    },
  ];
  areaList?.map((v: any) => {
    return (v.children = getParentData([
      { key: "continent", value: v.value },
      { key: "tab", value: "LightNode" },
    ]));
  });
  return areaList;
};

// 过滤footer不展示相同国家的vps路径
export const filterCountryVPS = ["jeddah-vps", "hanoi-vps", "washington-vps"];
// 中国地区特殊展示
export const chinaAreaVPS = ["hong-kong-vps", "taiwan-vps"];
