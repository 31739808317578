import footer from "./Footer/ru-RU.json";
import header from "./Header/ru-RU.json";
import customer from "./customer/ru-RU.json";
import support from "./Support/ru-RU.json";
import bottom from "./Bottom/ru-RU.json";
import nodeMap from "./NodeMap/ru-RU.json";
import Accordion from "./Accordion/ru-RU.json";
export default {
  ...footer,
  ...header,
  ...customer,
  ...support,
  ...bottom,
  ...nodeMap,
  ...Accordion,
};
