import React, { useEffect } from "react";
import { useRouter } from "next/router";
// 组件
import Header from "@/components/Header";
import Footer from "@/components/Footer";
// utils
import moment from "moment";

const momentLocale: any = {
  "en-US": "en",
  "zh-CN": "zh-cn",
  "zh-HK": "zh-hk",
  "fr-FR": "fr",
  "vi-VN": "vi",
  "ru-RU": "ru",
};
const Layout = (props: any) => {
  const router = useRouter();
  const locale = router.locale || "en-US";

  useEffect(() => {
    moment.locale(momentLocale?.[locale]);
  }, [locale]);

  return (
    <>
      <Header />
      {React.Children.map(props.children, (child) => {
        return React.cloneElement(child);
      })}
      <Footer />
    </>
  );
};
export default Layout;
