import US from "./en-US";
import CN from "./zh-CN";
import HK from "./zh-HK";
import FR from "./fr-FR";
import VN from "./vi-VN";
import RU from "./ru-RU";
export default {
  "en-US": US,
  "zh-CN": CN,
  "zh-HK": HK,
  "fr-FR": FR,
  "vi-VN": VN,
  "ru-RU": RU,
};
