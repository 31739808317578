import Link from "next/link";
import { useIntl } from "react-intl";
import { useRouter } from "next/router";
import Img from "@/components/Img";
import React, { useEffect, useState } from "react";
import { Popover, Typography, Dropdown, Menu } from "antd";
const { SubMenu } = Menu;
import icon1 from "@/static/images/icon-aboutUs.png";
import icon2 from "@/static/images/icon-news.png";
import { nodeMapData } from "@/utils/nodeData";
import LocationSeo from "./locationSeo";

interface MenuProps {
  title: string;
  activity: string;
  href?: string;
  isShowIcon?: boolean;
  children?: MenuProps[];
  target?: string;
  icon?: string;
}
const areaList = [
  {
    text: "Asia",
    value: "Asia",
  },
  {
    text: "Europe",
    value: "Europe",
  },
  {
    text: "NorthAmerica",
    value: "North America",
  },
  {
    text: "Africa",
    value: "Africa",
  },
  {
    text: "SouthAmerica",
    value: "South America",
  },
];

const HeadeMenu = (props: any) => {
  const [activity, setActivity] = useState<string>("");
  const { isMobile } = props;
  const intl = useIntl();
  const router = useRouter();
  const locale = router.locale || "en-US";
  const [isShow, setIsShow] = useState<boolean>(false);
  const [isChildShow, setIsChildShow] = useState<string>("");
  const [locationopenKeys, setLocationopenKeys] = useState<string[]>([]);
  const [menuData, setMenuData] = useState<any[]>([
    {
      title: "Home",
      activity: "home",
      href: `/${locale}`,
    },
    {
      title: "Product",
      activity: "product",
      href: `/${locale}/product`,
    },
    {
      title: "Location",
      activity: "location",
      isShowIcon: false,
    },
    {
      title: "aboutus",
      activity: "aboutUs content",
      isShowIcon: false,
      children: [
        {
          title: "aboutus",
          activity: "aboutUs",
          href: `/${locale}/aboutUs`,
          icon: icon1,
        },
        {
          title: "News",
          activity: "content",
          href: `/${locale}/content/69`,
          icon: icon2,
        },
      ],
    },
    {
      title: "Docs",
      activity: "docs",
      href: `https://doc.lightnode.com/`,
      target: "_blank",
    },
  ]);

  useEffect(() => {
    if (global.__webInfo?.id) {
      const data = [...menuData];
      data.forEach((v) => {
        if (v?.title === "aboutus" && v?.children) {
          v.children[1].href = `/${locale}/content/${global.__webInfo?.contentId}`;
        }
      });
      setMenuData(data);
    }
  }, [global.__webInfo]);

  const handleClose = (e: any) => {
    // e.preventDefault();
    setIsShow(!isShow);
    setIsChildShow("");
    props.onClose && props.onClose();
  };

  const handleShowLocation = () => {
    setIsShow(!isShow);
    setIsChildShow("");
  };

  const handleShowLocationChild = (value: string) => {
    if (isChildShow === value) {
      setIsChildShow("");
    } else {
      setIsChildShow(value);
    }
  };

  const handleShowSubMenu = (index: number) => {
    const arr = [...menuData];
    arr[index].isShowIcon = !arr[index].isShowIcon;
    setMenuData(arr);
  };

  const handleToUrl = (url: string) => {
    const _goLocale = locale === "zh-CN" || locale === "zh-HK" ? "zh/" : "";
    const html = "https://go.lightnode.com/" + _goLocale + url;
    window.open(html);
  };

  const getActivity = () => {
    //改pathname为asPath，pathname获取格式为content/[id],asPath为完整路劲content/69
    const { pathname, asPath } = router;
    const arr = asPath.split("/");
    let a: string = arr?.[arr.length - 1] || "home";
    if (arr.length > 2 && !isNaN(Number(arr?.[arr.length - 1]))) {
      a = arr?.[arr.length - 2];
    }
    setActivity(a);
  };

  useEffect(() => {
    getActivity();
  }, [router?.pathname]);
  const locationMenu = (
    <Menu
      mode="vertical"
      multiple={false}
      openKeys={locationopenKeys}
      onOpenChange={(openKeys) => {
        setLocationopenKeys(
          openKeys?.length ? [openKeys[openKeys.length - 1]] : []
        );
      }}
      forceSubMenuRender={true}
    >
      {areaList.map((item: any) => {
        return (
          <SubMenu
            key={item.value}
            title={intl.formatMessage({
              id: `components.header.${item.text}`,
            })}
          >
            {nodeMapData.map((v: any) => {
              if (v.continent === item.value && v?.tab?.includes("LightNode")) {
                return (
                  <Menu.Item
                    onClick={() => {
                      handleToUrl(v?.url || "");
                    }}
                    key={v.value}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {v?.countryImgCode ? (
                        <span
                          className={`fi fi-${v.countryImgCode} flag`}
                          style={{ marginRight: 5, width: 24, height: 18 }}
                        ></span>
                      ) : (
                        <Img
                          style={{ marginRight: 5, width: 24, height: 18 }}
                          src={v.countryImg}
                          alt={""}
                        />
                      )}
                      {`${v?.city?.[locale] || v?.city?.["en-US"]} VPS`}
                    </div>
                  </Menu.Item>
                );
              }
            })}
          </SubMenu>
        );
      })}
    </Menu>
  );
  return (
    <>
      <div
        className="header-menu"
        style={locale === "fr-FR" ? { flexWrap: "inherit" } : {}}
      >
        {menuData?.map((v: any, i: number) => {
          let menu: any = null;
          if (v?.children) {
            menu = (
              <div className="header-submenu item-ul">
                {v?.children.map((m: any, j: number) => {
                  return (
                    <div className="item-li" key={j}>
                      <Link href={m.href}>
                        <a>
                          <Img
                            src={m.icon}
                            alt={intl.formatMessage({
                              id: `components.header.${m.title}`,
                            })}
                          />
                          {intl.formatMessage({
                            id: `components.header.${m.title}`,
                          })}
                        </a>
                      </Link>
                    </div>
                  );
                })}
              </div>
            );
          }
          return (
            <React.Fragment key={i}>
              {v.activity === "location" && !isMobile ? (
                <div className="item-li">
                  <Dropdown
                    overlay={locationMenu}
                    overlayClassName="k-setlocaleStyle"
                  >
                    <Typography.Text ellipsis={true}>
                      {intl.formatMessage({
                        id: `components.header.${v.title}`,
                      })}
                    </Typography.Text>
                  </Dropdown>
                </div>
              ) : (
                <div
                  className={`item-li ${[
                    activity && v.activity.indexOf(activity) !== -1
                      ? "active"
                      : "",
                    v.children
                      ? `hasChildren ${v.isShowIcon ? "show" : ""}`
                      : "",
                  ].join(" ")}`}
                  onClick={
                    v.children
                      ? (e: any) => {
                          e.preventDefault();
                          handleShowSubMenu(i);
                        }
                      : handleClose
                  }
                >
                  <Popover
                    content={menu}
                    overlayStyle={v.children ? {} : { display: "none" }}
                    placement="bottom"
                    overlayClassName="header-subMenu"
                  >
                    {v?.target ? (
                      <a
                        href={v?.href}
                        target={v?.target}
                        rel="noopener noreferrer"
                      >
                        <Typography.Text ellipsis={true}>
                          {intl.formatMessage({
                            id: `components.header.${v.title}`,
                          })}
                        </Typography.Text>
                      </a>
                    ) : v.href ? (
                      <Link
                        href={v?.href}
                        title={intl.formatMessage({
                          id: `components.header.${v.title}`,
                        })}
                      >
                        <Typography.Text ellipsis={true}>
                          {intl.formatMessage({
                            id: `components.header.${v.title}`,
                          })}
                        </Typography.Text>
                      </Link>
                    ) : (
                      <Typography.Text ellipsis={true}>
                        {intl.formatMessage({
                          id: `components.header.${v.title}`,
                        })}
                      </Typography.Text>
                    )}
                  </Popover>
                </div>
              )}
              {v.activity === "location" && isMobile ? (
                <div className="item-ul">
                  <div
                    onClick={handleShowLocation}
                    className={`item-li ${[
                      "k-setLocale-locale hasChildren",
                      isShow ? "show" : "",
                    ].join(" ")}`}
                  >
                    <span>
                      {intl.formatMessage({ id: "components.header.Location" })}
                    </span>
                  </div>
                  <div
                    className="item-ul"
                    style={{ paddingLeft: "1em", marginBottom: 0 }}
                  >
                    {areaList.map((item: any) => {
                      return isShow ? (
                        <React.Fragment key={item.value}>
                          <div
                            className={`item-li ${[
                              "k-setLocale-locale hasChildren",
                              isChildShow === item.value ? "show" : "",
                            ].join(" ")}`}
                            onClick={() => {
                              handleShowLocationChild(item.value);
                            }}
                            key={item.value}
                          >
                            {intl.formatMessage({
                              id: `components.header.${item.text}`,
                            })}
                          </div>
                          <div
                            className="item-ul"
                            style={{ paddingLeft: "1em", marginBottom: 0 }}
                          >
                            {nodeMapData.map((v: any) => {
                              return isChildShow === item.value &&
                                isChildShow === v.continent ? (
                                <div
                                  className="item-li k-child-vps"
                                  onClick={(e: any) => {
                                    handleClose(e);
                                    handleToUrl(v.url);
                                  }}
                                  key={v.value}
                                >
                                  {v?.countryImgCode ? (
                                    <span
                                      className={`fi fi-${v.countryImgCode} flag`}
                                    ></span>
                                  ) : (
                                    <Img src={v.countryImg} alt={""} />
                                  )}
                                  {`${
                                    v?.city?.[locale] || v?.city?.["en-US"]
                                  } VPS`}
                                </div>
                              ) : null;
                            })}
                          </div>
                        </React.Fragment>
                      ) : null;
                    })}
                  </div>
                </div>
              ) : null}
              {v.isShowIcon && isMobile ? (
                <div
                  className="item-ul"
                  style={{ paddingLeft: "1em", marginBottom: 0 }}
                >
                  {v?.children?.map((m: any, j: number) => {
                    return (
                      <div
                        onClick={handleClose}
                        className={`item-li ${
                          m.activity.indexOf(activity) !== -1 ? "active" : ""
                        }`}
                        key={j}
                      >
                        <Link href={m.href}>
                          {intl.formatMessage({
                            id: `components.header.${m.title}`,
                          })}
                        </Link>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </React.Fragment>
          );
        })}
      </div>
      {/* location下来菜单数据做seo抓取处理 */}
      <div style={{ display: "none" }}>
        <LocationSeo areaList={areaList} nodeMapData={nodeMapData} />
      </div>
    </>
  );
};
export default HeadeMenu;
