// import { NextRouter } from "next/router";
import moment from "moment";
import Logo from "@/static/images/header-logo.png";

// 默认的平台数据（防止分享链接时TDK为undefined）
export const webData: any = {
  consoleUrl: "https://console.lightnode.com", //控制台登录页
  registerUrl: "https://console.lightnode.com/user/register", //控制台注册页
  domainName: "console.lightnode.com", //平台域名
  platformName: "LightNode", //平台名称
  origin: "https://lightnode.com", //域名
  id: /* 145 34  */ 0,
  deputyLogoUrl: "",
  logoUrl: Logo,
  proto: "https://",
  contentId: 0, //文章分类id
};

const lang = ["en-US", "zh-CN", "zh-HK", "fr-FR", "vi-VN", "ru-RU"];
// 获取相关平台信息
export function getWebData(data: any) {
  const inviteCode = data.query?.inviteCode || data.inviteCode || "";
  const promoteWay = data.query?.promoteWay || data.promoteWay || "";
  const promoteId = data.query?.ref || data.promoteId || "";
  const domainName = data?.domainName || webData.domainName;
  const _locale = lang.includes(data.locale || "en-US") ? data.locale : "en-US";
  let param = `?lang=${_locale}`;
  // 邀请链接
  if (inviteCode && promoteWay) {
    param = `${param}&inviteCode=${inviteCode}&promoteWay=${promoteWay}`;
  } else if (promoteId) {
    // 落地页
    param = `${param}&promoteId=${promoteId}&promoteWay=ADVERT`;
  }
  let promoteTime = data.promoteTime || "";
  if (
    (data.query?.ref && data.query?.ref !== data.promoteId) ||
    (data.promoteTime && !moment().isSame(data.promoteTime, "day"))
  ) {
    //做接口日期判断用处
    promoteTime = moment().format("YYYY-MM-DD");
  }
  //不存储data的deputyLogoUrl和logoUrl，base64过长
  const info = {
    ...webData,
    ...data,
    // deputyLogoUrl: "",
    logoUrl: Logo, //base64存储值太长，暂不用
    consoleUrl: data.proto + domainName + param,
    registerUrl: data.proto + domainName + "/user/register" + param,
    domainName,
    platformName: data?.platformName || webData.platformName,
    id: data?.id || 0,
    param,
    contentId: data?.contentId || (data?.id === 145 ? 14 : 69), //测试环境和生产环境固定值
    origin: data.proto + data.host,
    inviteCode,
    promoteWay,
    promoteId,
    promoteTime,
    locale: _locale,
  };
  //服务端全局存储
  global.__webInfoServer = info;
}
